import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { Collapse } from "antd";
const { Panel } = Collapse;

export default () => {
  return (
    <>
      <div className="brd_footer_main">
        <div className="midd-width-ifti">
          <div className="footer-new-design">
            <div className="brd-footer-top">
              <div className="box-apps brd-footer-box">
                <h4>Download Our App</h4>
                <ul>
                  <li>
                    <a href="https://apps.apple.com/app/id1618762939" target="_">
                      <i className="fa fa-apple" aria-hidden="true"></i> Download for iOS
                    </a>
                  </li>
                  <li>
                    <a href="https://bit.ly/3mxLJLK" target="_">
                      <i className="fa fa-android" aria-hidden="true"></i> Download for Android
                    </a>
                  </li>
                </ul>
              </div>
              <div className="brd-footer-box">
                <h4>About</h4>
                <ul>
                  <li>
                    <NavLink to="/retailer-brand">Retailers & Brands</NavLink>
                  </li>
                  <li>
                    <NavLink to="/influencer-creator">Influencers & Creators</NavLink>
                  </li>
                  <li>
                    <NavLink to="/about-us">About Us</NavLink>
                  </li>
                  <li>
                    <NavLink to="/terms-service">Terms & Conditions</NavLink>
                  </li>
                  <li>
                    <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                  </li>
                  <li>
                    <NavLink to="/faqs">FAQs</NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact-us">Contact Us</NavLink>
                  </li>
                </ul>
              </div>
              <div className="brd-footer-box">
                <h4>Popular Brands</h4>
                <ul>
                  <li>
                    <NavLink to="/dl1961denim">DL1961</NavLink>
                  </li>
                  <li>
                    <NavLink to="/kbiouser1">Kohl's</NavLink>
                  </li>
                  <li>
                    <NavLink to="/900000069">Judith Ripka</NavLink>
                  </li>

                  <li>
                    <NavLink to="/warpweftworld">Warp + Weft</NavLink>
                  </li>
                  <li>
                    <NavLink to="/kbiouserp">Asos</NavLink>
                  </li>
                </ul>
              </div>
              <div className="brd-footer-box">
                <h4>popular Categories</h4>
                <ul>
                  <li>
                    <NavLink to="/sub-category/61bb0aa95ab5583598251b41">Accessories</NavLink>
                  </li>
                  <li>
                    <NavLink to="/sub-category/6166e7368d5dd60891f08d43">Bags</NavLink>
                  </li>
                  <li>
                    <NavLink to="/sub-category/61a5b8cf4eaa2cb344e200f8">Beauty</NavLink>
                  </li>
                  <li>
                    <NavLink to="/sub-category/6191eb41d4e4b5c44013cd4f">Clothing</NavLink>
                  </li>
                  <li>
                    <NavLink to="/sub-category/6166e77f8d5dd60891f08d77">Electronics</NavLink>
                  </li>
                  <li>
                    <NavLink to="/sub-category/616fffba214809dea33693a8">Home Decor</NavLink>
                  </li>
                  <li>
                    <NavLink to="/sub-category/616ffff4214809dea3369435">Jewelry</NavLink>
                  </li>
                  <li>
                    <NavLink to="/sub-category/6166e7788d5dd60891f08d73">Shoes</NavLink>
                  </li>
                  <li>
                    <NavLink to="/sub-category/6209e1695800fadc9655a71c">Sports</NavLink>
                  </li>
                  <li>
                    <NavLink to="/sub-category/616fffcb214809dea33693f8">Travel</NavLink>
                  </li>
                </ul>
              </div>
              <div className="brd-footer-box">
                <h4>Promotions</h4>
                <ul>
                  <li>
                    <a href="javascript:void">Black Friday</a>
                  </li>
                  <li>
                    <a href="javascript:void">Cyber Monday</a>
                  </li>
                  <li>
                    <a href="javascript:void">Boxing Day</a>
                  </li>
                  <li>
                    <a href="javascript:void">Memorial Day</a>
                  </li>
                  <li>
                    <a href="javascript:void">Daily Deals</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="brd_footer">
              <div className="brd-copyright">© 2022 KonnectBio Inc.</div>
              <div className="brd-right">
                <div className="brd-social">
                  <span className="fa-stack">
                    <a href="https://www.instagram.com/konnectbio" target="_blank">
                      <i className="fa fa-circle fa-stack-2x"></i>
                      <i className="fa fa-instagram fa-stack-1x"></i>
                    </a>
                  </span>
                  <span className="fa-stack">
                    <a href="https://www.linkedin.com/company/konnectbio-inc/" target="_blank">
                      <i className="fa fa-circle fa-stack-2x"></i>
                      <i className="fa fa-linkedin fa-stack-1x"></i>
                    </a>
                  </span>
                  <span className="fa-stack">
                    <a href="https://www.facebook.com/konnectbio" target="_blank">
                      <i className="fa fa-circle fa-stack-2x"></i>
                      <i className="fa fa-facebook fa-stack-1x"></i>
                    </a>
                  </span>
                  <span className="fa-stack">
                    <a href="https://www.youtube.com/channel/UCoEkTdhPjoAD2NTs0legNwg" target="_blank">
                      <i className="fa fa-circle fa-stack-2x"></i>
                      <i className="fa fa-youtube-play fa-stack-1x"></i>
                    </a>
                  </span>
                </div>
                {/* <div className="brd-social">
										<span className="fa-stack">
											<a href="javascript:void">
												<i className="fa fa-circle fa-stack-2x"></i>
												<i className="fa fa-instagram fa-stack-1x"></i>
											</a>
										</span>
										<span className="fa-stack">
											<a href="javascript:void">
												<i className="fa fa-circle fa-stack-2x"></i>
												<i className="fa fa-linkedin fa-stack-1x"></i>
											</a>
										</span>
										<span className="fa-stack">
											<a href="javascript:void">
												<i className="fa fa-circle fa-stack-2x"></i>
												<i className="fa fa-facebook fa-stack-1x"></i>
											</a>
										</span>
										<span className="fa-stack">
											<a href="javascript:void">
												<i className="fa fa-circle fa-stack-2x"></i>
												<i className="fa fa-youtube-play fa-stack-1x"></i>
											</a>
										</span>
									</div> */}
              </div>
              {/* <div className="f-right-temporary">
								<div className="terms-link-temporary">
									<ul>
										<li>
											<NavLink to="/terms-service">Terms & Conditions</NavLink>
										</li>
										<li>
											<NavLink to="/privacy-policy">Privacy Policy</NavLink>
										</li>
										<li>
											<NavLink to="/contact-us">Contact Us</NavLink>
										</li>
									</ul>
								</div>
								
							</div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="mobile_footer_ifti">
        <Collapse accordion expandIconPosition={"end"} defaultActiveKey={["1"]} expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}>
          <Panel header="About" key="1">
            <div className="brd-footer-box">
              <ul>
                <li>
                  <NavLink to="/influencer-creator">Influencers & Creators</NavLink>
                </li>
                <li>
                  <NavLink to="/retailer-brand">Retailers & Brands</NavLink>
                </li>
                <li>
                  <NavLink to="/about-us">About Us</NavLink>
                </li>
                <li>
                  <NavLink to="/terms-service">Terms & Conditions</NavLink>
                </li>
                <li>
                  <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                </li>
                <li>
                  <NavLink to="/contact-us">Contact Us</NavLink>
                </li>
              </ul>
            </div>
          </Panel>
          <Panel header="Popular Brands" key="2">
            <div className="brd-footer-box">
              <ul>
                <li>
                  <NavLink to="/dl1961denim">DL1961</NavLink>
                </li>
                <li>
                  <NavLink to="/kbiouser1">Kohl's</NavLink>
                </li>
                <li>
                  <NavLink to="/900000069">Judith Ripka</NavLink>
                </li>
                <li>
                  <NavLink to="/warpweftworld">Warp + Weft</NavLink>
                </li>
                <li>
                  <NavLink to="/kbiouserp">Asos</NavLink>
                </li>
              </ul>
            </div>
          </Panel>
          <Panel header="popular Categories" key="3">
            <div className="brd-footer-box">
              <ul>
                <li>
                  <NavLink to="/sub-category/61bb0aa95ab5583598251b41">Accessories</NavLink>
                </li>
                <li>
                  <NavLink to="/sub-category/6166e7368d5dd60891f08d43">Bags</NavLink>
                </li>
                <li>
                  <NavLink to="/sub-category/61a5b8cf4eaa2cb344e200f8">Beauty</NavLink>
                </li>
                <li>
                  <NavLink to="/sub-category/6191eb41d4e4b5c44013cd4f">Clothing</NavLink>
                </li>
                <li>
                  <NavLink to="/sub-category/6166e77f8d5dd60891f08d77">Electronics</NavLink>
                </li>
                <li>
                  <NavLink to="/sub-category/616fffba214809dea33693a8">Home Decor</NavLink>
                </li>
                <li>
                  <NavLink to="/sub-category/616ffff4214809dea3369435">Jewelry</NavLink>
                </li>
                <li>
                  <NavLink to="/sub-category/6166e7788d5dd60891f08d73">Shoes</NavLink>
                </li>
                <li>
                  <NavLink to="/sub-category/6209e1695800fadc9655a71c">Sports</NavLink>
                </li>
                <li>
                  <NavLink to="/sub-category/616fffcb214809dea33693f8">Travel</NavLink>
                </li>
              </ul>
            </div>
          </Panel>
          <Panel header="Download Our App" key="4">
            <div className="brd-footer-box">
              <ul>
                <li>
                  <a href="https://apps.apple.com/app/id1618762939" target="_">
                    <i className="fa fa-apple" aria-hidden="true"></i> Download for iOS
                  </a>
                </li>
                <li>
                  <a href="https://bit.ly/3mxLJLK" target="_">
                    <i className="fa fa-android" aria-hidden="true"></i> Download for Android
                  </a>
                </li>
              </ul>
            </div>
          </Panel>
          <Panel header="Promotions" key="5">
            <div className="brd-footer-box">
              <ul>
                <li>
                  <a href="javascript:void">Black Friday</a>
                </li>
                <li>
                  <a href="javascript:void">Cyber Monday</a>
                </li>
                <li>
                  <a href="javascript:void">Boxing Day</a>
                </li>
                <li>
                  <a href="javascript:void">Memorial Day</a>
                </li>
                <li>
                  <a href="javascript:void">Daily Deals</a>
                </li>
              </ul>
            </div>
          </Panel>
        </Collapse>

        <div className="brd_footer">
          <div className="brd-right">
            <div className="brd-social">
              <span className="fa-stack">
                <a href="https://www.instagram.com/konnectbio" target="_blank">
                  <i className="fa fa-circle fa-stack-2x"></i>
                  <i className="fa fa-instagram fa-stack-1x"></i>
                </a>
              </span>
              <span className="fa-stack">
                <a href="https://www.linkedin.com/company/konnectbio-inc/" target="_blank">
                  <i className="fa fa-circle fa-stack-2x"></i>
                  <i className="fa fa-linkedin fa-stack-1x"></i>
                </a>
              </span>
              <span className="fa-stack">
                <a href="https://www.facebook.com/konnectbio" target="_blank">
                  <i className="fa fa-circle fa-stack-2x"></i>
                  <i className="fa fa-facebook fa-stack-1x"></i>
                </a>
              </span>
              <span className="fa-stack">
                <a href="https://www.youtube.com/channel/UCoEkTdhPjoAD2NTs0legNwg" target="_blank">
                  <i className="fa fa-circle fa-stack-2x"></i>
                  <i className="fa fa-youtube-play fa-stack-1x"></i>
                </a>
              </span>
            </div>
            {/* <div className="brd-social">
							<span className="fa-stack">
								<a href="javascript:void">
									<i className="fa fa-circle fa-stack-2x"></i>
									<i className="fa fa-instagram fa-stack-1x"></i>
								</a>
							</span>
							<span className="fa-stack">
								<a href="javascript:void">
									<i className="fa fa-circle fa-stack-2x"></i>
									<i className="fa fa-linkedin fa-stack-1x"></i>
								</a>
							</span>
							<span className="fa-stack">
								<a href="javascript:void">
									<i className="fa fa-circle fa-stack-2x"></i>
									<i className="fa fa-facebook fa-stack-1x"></i>
								</a>
							</span>
							<span className="fa-stack">
								<a href="javascript:void">
									<i className="fa fa-circle fa-stack-2x"></i>
									<i className="fa fa-youtube-play fa-stack-1x"></i>
								</a>
							</span>
						</div> */}
          </div>
          <div className="brd-copyright">© 2022 KonnectBio Inc.</div>
        </div>
      </div>
    </>
  );
};
