import { combineReducers } from "redux";
import category from "./category/subCategory.reducer";
import allBrand from "./brand/allBrand.reducer";
import featureBrand from "./brand/featureBrand.reducer";
import parentCategory from "./category/parentCategory.reducer";
import popularCategory from "./category/popularCategory.reducer";
import subCategory from "./category/subCategory.reducer";
import multipleSubCategory from "./category/multipleSubCategory.reducer";
import categoryWiseBrand from "./brand/categoryWiseBrand.reducer";
import categoryWiseInfluencer from "./influencer/categoryWiseInfluencer";
import allInfluencer from "./influencer/allInfluencer.reducer";
import bioShopMenu from "./bioShop/bioShopMenu.reducer";
import bioShopAllPost from "./bioShop/bioShopAllPost.reducer";
import bioShopAllLink from "./bioShop/bioShopAllLink.reducer";
import bioShopCategoryPost from "./bioShop/bioShopCategoryPost.reducer";
import url from "./bioShop/bioShopAddToUrl.reducer";
import bioShopPostClick from "./bioShop/bioShopPostClick.reducer";
import bioShopViewCount from "./bioShop/bioShopViewcount.reducer";
import bioShopAddToFavourite from "./bioShop/bioShopAddToFavourite.reducer";
import login from "./login/login.reducer";
import showAuthModal from "./login/login.modal.reducer";
import country from "./country/country.reducer";
import state from "./state/state.reducer";
import city from "./city/city.reducer";
import customerRegister from "./register/customerRegister.reducer";
import brandRegister from "./register/brandRegister.reducer";
import homeBanner from "./banner/banner.reducer";
import categoryBanner from "./banner/categoryBanner.reducer";
import hotDeal from "./deal/hotDeal.reducer";
import favouriteBrand from "./brand/favouriteBrand.reducer";
import categoryFavouriteBrand from "./brand/categoryFavouriteBrand.reducer";
import categoryFeatureBrand from "./brand/categoryFeatureBrand.reducer";
import changePassword from "./changePassword/changePassword.reducer";
import selectedCountry from "./country/selectedCountry.reducer";
import showChangePasswordModal from "./changePassword/changePassword.modal.reducer";
import account from "./account/account.reducer";
import accountUpdate from "./account/accountUpdate.reducer";
import becomeInfluencer from "./becomeInfluencer/becomeInfluencer.reducer";
import favouriteList from "./favourite/favouriteList.redcuer";
import favouriteListRemove from "./favourite/favouriteListRemove.reducer";
import favouriteRemove from "./favourite/favouriteRemove.reducer";

import subcategories from "./BioSubCategory/BioSubCategory.redcuer";
import allCategory from "./category/allCategory.reducer";
import saveCategory from "./category/saveCategory.reducer";
import verifyCategory from "./category/verifyCategory.reducer";
//Brand Favourite List
import favouriteBrandList from "./favourite/favouriteBrandList.reducer";
import favouriteBrandRemove from "./favourite/favouriteBrandRemove.reducer";
import bioShopAddToFavouriteBrand from "./favourite/favouriteBrandAdd.reducer";
import favouriteInfluencerList from "./favourite/favouriteInfluencerList.reducer";
import forgetPassword from "./forgetPassword/forgetPassword.reducer";
import resetPassword from "./resetPassword/resetPassword.reducer";
//Purchase
import purchaseReport from "./purchase/purchaseReport.reducer";
//
import showRegModal from "./register/register.modal.reducer";
import commission from "./wallet/commission.reducer";
import commissionEarn from "./wallet/commissionEarn.reducer";
import withDrawRequest from "./wallet/withdrawRequest.reducer";
import getAllWithDraw from "./wallet/getAllWithDrawRequest.reducer";
import brandWallet from "./wallet/brandWallet.reducer";
import contact from "./contact/contact.reducer";
import referralStats from "./sales/referralStats.reducer";
//Sub Category
import subCategoryWiseBrand from "./brand/subCategoryWiseBrand.reducer";
import categoriesSetup from "./category/categoriesSetup";
//Live Event
import currentLiveEvent from "./liveEvent/liveEvent.reducer";
import liveEventId from "./liveEvent/liveEventById.reducer";
import allUpcomingEvent from "./liveShopping/allUpcomingEvent.reducer";
import upComingEvent from "./liveShopping/upComingEvent.reducer";
import allLiveEvent from "./liveShopping/allLiveEvent.reducer";
import liveEvent from "./liveShopping/liveEvent.reducer";
import allPublishEvent from "./liveEvent/getPublishEvent.reducer";
import eventViews from "./liveEvent/getView.reducer";
import eventLikes from "./liveEvent/getEventLikes.reducer";
//Shopify GetProduct
import shopifyProduct from "./shopify/shopify.getProduct.reducer";
import shopifyaddToCart from "./shopify/shopify.addToCart.reducer";
import getShopifyLiveCart from "./liveEvent/getShopifyLiveCart.reducer";
import checkoutProduct from "./shopify/shopify.getCheckout.reducer";
import removeCart from "./shopify/shopify.removeCart.reducer";
import shopifySetShippingAddress from "./shopify/shopify.setShippingAddress.reducer";
import shopifyGetShippingRates from "./shopify/shopify.getShippingRates.reducer";
import shopifySetShippingRates from "./shopify/shopify.setShippingRates.reducer";
import shopifyApplyPromoCode from "./shopify/shopify.applyPromoCode.reducer";
import shopifyRemovePromoCode from "./shopify/shopify.removePromoCode.reducer";
import shopifyCompleteCheckout from "./shopify/shopify.completeCheckout.reducer";
import shopifyUpdateAttribute from "./shopify/shopify.updateAttribute.reducer";
import emptyCart from "./shopify/shopify.emptyCart.reducer";
//Payment
import addCreditCard from "./payment/addCreditCard.reducer";
import allCreditCard from "./payment/getAllCreditCard.reducer";
import defaultCard from "./payment/setDefaultCard.reducer";
//Shipping
import addShippingAddress from "./shipping/createShippingAddress.reducer";
import allShippingAddress from "./shipping/getAllShippingAddress.reducer";
import setShippingAddress from "./shipping/setShippingAddress.reducer";
import upgradeShippingAddress from "./shipping/updatedShippingAddress.reducer";
import deleteShippingAddress from "./shipping/deleteShippingAddress.reducer";
import chatToken from "./chat/chatToken";
import getChat from "./chat/getChat";
import saveChat from "./chat/saveChat";
import dashboardPayloadSocial from "./dashboard/dashboard.reducer";
import dashboardPayloadEvent from "./dashboard/dashboard.event.reducer";
//Instagram
import getInstagramUrl from "./instagramConnection/getInstagramUrl.reducer";
import instagramData from "./instagramConnection/getInstagramData.reducer";
import updateAccessToken from "./instagramConnection/updateAccessToken.reducer";
import disconnectInstagram from "./instagramConnection/disconnectInstagram.reducer";
import refreshedInstagram from "./instagramConnection/refreshInstagram.reducer";
import instagramMedia from "./instagramConnection/getInstagramedia.reducer";
import instaNextmedia from "./instagramConnection/instaNextmedia.reducer";
// 
import galleryPosting from "./galleryPosts/galleryPostimg.reducer";
import schedulePosts from "./mediaGallery/schedulePost";
import campaign from "./mediaGallery/mediaSummary";
import gallery from "./mediaGallery/gallery";
import createdMedia from "./mediaGallery/postMedia.reducer";
import deletetedMedia from "./mediaGallery/deleteMedia.reducer";

//
import bioPosts from "./bioPost/bioPost.reducer";
import getEvent from "./event/getEvent.reducer";
import publishedPost from "./galleryPosts/publishPost.reducer"

import refreshedfacebook from "./facebookConnection/facebookConnect.reducer";
import retrievedPost from "./galleryPosts/retrievePost.reducer";
import revisedPost from "./galleryPosts/revisePost.reducer";
import removedPost from "./galleryPosts/removePost.reducer";
import reservedPost from "./galleryPosts/reservePost.reducer";
import receivedPost from "./galleryPosts/receivePost.reducer";
import subCategoryig from "./category/subCategoryig.reducer";

import brand from "./brand/brand.reducer";
import reviseStatusPost from "./galleryPosts/postReviseStatus.reducer";

export default combineReducers({
  addShippingAddress,
  allShippingAddress,
  allLiveEvent,
  addCreditCard,
  account,
  accountUpdate,
  allBrand,
  allInfluencer,
  allUpcomingEvent,
  upComingEvent,
  bioShopAddToFavourite,
  bioShopAddToFavouriteBrand,
  becomeInfluencer,
  bioShopAllLink,
  bioShopAllPost,
  bioShopCategoryPost,
  bioShopMenu,
  bioShopPostClick,
  bioShopViewCount,
  brandRegister,
  brandWallet,
  category,
  referralStats,
  currentLiveEvent,
  commission,
  contact,
  commissionEarn,
  categoryBanner,
  categoryFavouriteBrand,
  categoryFeatureBrand,
  categoryWiseBrand,
  categoryWiseInfluencer,
  changePassword,
  city,
  country,
  customerRegister,
  favouriteBrand,
  featureBrand,
  favouriteList,
  favouriteListRemove,
  favouriteRemove,
  favouriteBrandList,
  favouriteInfluencerList,
  favouriteBrandRemove,
  forgetPassword,
  homeBanner,
  hotDeal,
  liveEvent,
  login,
  parentCategory,
  popularCategory,
  purchaseReport,
  selectedCountry,
  showAuthModal,
  defaultCard,
  state,
  subCategory,
  showChangePasswordModal,
  subCategoryWiseBrand,
  showRegModal,
  setShippingAddress,
  upgradeShippingAddress,
  deleteShippingAddress,
  url,
  withDrawRequest,

  allCreditCard,
  liveEventId,
  resetPassword,
  multipleSubCategory,
  shopifyProduct,
  shopifySetShippingAddress,
  shopifyaddToCart,
  shopifyGetShippingRates,
  shopifyApplyPromoCode,
  shopifySetShippingRates,
  getShopifyLiveCart,
  shopifyRemovePromoCode,
  shopifyCompleteCheckout,
  shopifyUpdateAttribute,
  checkoutProduct,
  removeCart,
  emptyCart,
  getInstagramUrl,
  instagramData,
  updateAccessToken,
  disconnectInstagram,
  refreshedInstagram,
  subcategories,
  allCategory,
  saveCategory,
  verifyCategory,
  categoriesSetup,
  allPublishEvent,
  eventViews,
  myChatToken: chatToken,
  getChat,
  saveChat,
  dashboardPayloadSocial,
  dashboardPayloadEvent,
  getAllWithDraw,
  galleryPosting,
  refreshedfacebook,
  instagramMedia,
  instaNextmedia,
  retrievedPost,
  revisedPost,
  removedPost,
  reservedPost,
  publishedPost,
  receivedPost,
  subCategoryig,
  schedulePosts,
  campaign,
  gallery,
  createdMedia,
  deletetedMedia,
  bioPosts,
  getEvent,
  brand,
  reviseStatusPost,
});
