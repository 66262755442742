import { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import "../../css/live-events.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";

export default function PopularChannel() {
  let userInfo = localStorage.getItem("userInfo");
  if (userInfo !== "undefined") {
    userInfo = JSON.parse(localStorage.getItem("userInfo"));
  } else {
    localStorage.removeItem("userInfo");
    window.location.reload();
  }

  const popularchannels = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 7.5,
    swipe: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 6.2,
          swipe: true,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 5.2,
          swipe: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
          swipe: true,
        },
      },
    ],
  };

  return (
    <>
      <div className="live-shows">
        <div className="event-container popular-channels-sec">
          <div className="event-header">
            <h3>Popular channels</h3>
          </div>
          <Slider {...popularchannels}>
            <div className="event-col-box">
              <NavLink to="/dl1961denim" className="live-vid-link">
                <div className="vid-item">
                  <img alt="" src="https://www.instyle.com/thmb/o9zDkWS9Xh01CzjC32Q-3ElHK0Q=/400x0/filters:no_upscale():max_bytes(150000):strip_icc()/emrata-dl1961-jeans-campaign-new-embed-02-c2be4bc733c84b4784f02be76f81f881.jpg" />
                  <div className="vid-item-detail">
                    <div className="vid-title">
                      <div className="author-name">
                        <span className="auth-image">
                          <img alt="" src="https://static.konnect.bio/banner/9a87d50f-2032-44b7-a982-942704564b84.jpg" />
                        </span>
                        <span className="auth-title">DL1961 Premium Denim</span>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
            <div className="event-col-box">
              <NavLink to="/warpweftworld" className="live-vid-link">
                <div className="vid-item">
                  <img alt="" src="https://i.pinimg.com/originals/4d/21/8e/4d218ef128ea24ad7683fcb2b0c96854.jpg" />
                  <div className="vid-item-detail">
                    <div className="vid-title">
                      <div className="author-name">
                        <span className="auth-image">
                          <img alt="" src="https://static.konnect.bio/banner/4f8a2fab-320b-4429-a4b0-afabb291b06d.jpg" />
                        </span>
                        <span className="auth-title">Warp + Weft</span>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
            <div className="event-col-box">
              <NavLink to="/kbiouser1" className="live-vid-link">
                <div className="vid-item">
                  <img alt="" src="https://imgmedia.lbb.in/media/2020/08/20/BE9FF1DE-38E8-4CAA-9DD1-B75D8E883806.jpg" />
                  <div className="vid-item-detail">
                    <div className="vid-title">
                      <div className="author-name">
                        <span className="auth-image">
                          <img alt="" src="https://static.konnect.bio/banner/7d9d4090-26a7-4acf-9b3d-a09cac674f01.jpg" />
                        </span>
                        <span className="auth-title">Kohl's</span>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
            <div className="event-col-box">
              <NavLink to="/900000069" className="live-vid-link">
                <div className="vid-item">
                  <img alt="" src="http://cdn.shopify.com/s/files/1/0413/6779/7922/products/JR_Vienna_New_7_5844f6be-ff48-486a-b3d4-0d16c652dc37_1800x1800_1_1200x1200.jpg?v=1642101968" />
                  <div className="vid-item-detail">
                    <div className="vid-title">
                      <div className="author-name">
                        <span className="auth-image">
                          <img alt="" src="https://static.konnect.bio/banner/07541cb3-e242-4f29-a4c1-2d7a5efb166a.jpg" />
                        </span>
                        <span className="auth-title">Judith Ripka</span>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
            <div className="event-col-box">
              <NavLink to="/900000495" className="live-vid-link">
                <div className="vid-item">
                  <img alt="" src="https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/ff-collection-shot-models-original-1629924514.jpg" />
                  <div className="vid-item-detail">
                    <div className="vid-title">
                      <div className="author-name">
                        <span className="auth-image">
                          <img alt="" src="https://static.konnect.bio/banner/d73b7f9b-2e5b-4c94-9c87-6a1899723b3e.jpg" />
                        </span>
                        <span className="auth-title">Sephora</span>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
            <div className="event-col-box">
              <NavLink to="/900000496" className="live-vid-link">
                <div className="vid-item">
                  <img alt="" src="https://media1.popsugar-assets.com/files/thumbor/HDjdstm17IyKfb4D4dp21SZiHBw/fit-in/2048xorig/filters:format_auto-!!-:strip_icc-!!-/2018/08/29/701/n/44285655/1e399895360866ee_AdidasOriginal_FW18_Falcon_B28126_Look2_FullBody_0190_03/i/Kylie-Jenner-Adidas-Falcon-Sneakers-2018.jpg" />
                  <div className="vid-item-detail">
                    <div className="vid-title">
                      <div className="author-name">
                        <span className="auth-image">
                          <img alt="" src="https://static.konnect.bio/banner/98a0b149-cf5f-4f0f-afbf-461bed6c25b1.jpg" />
                        </span>
                        <span className="auth-title">Adidas</span>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
            <div className="event-col-box">
              <NavLink to="/900000495" className="live-vid-link">
                <div className="vid-item">
                  <img alt="" src="https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/ff-collection-shot-models-original-1629924514.jpg" />
                  <div className="vid-item-detail">
                    <div className="vid-title">
                      <div className="author-name">
                        <span className="auth-image">
                          <img alt="" src="https://static.konnect.bio/banner/d73b7f9b-2e5b-4c94-9c87-6a1899723b3e.jpg" />
                        </span>
                        <span className="auth-title">Sephora</span>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
            <div className="event-col-box">
              <NavLink to="/900000069" className="live-vid-link">
                <div className="vid-item">
                  <img alt="" src="http://cdn.shopify.com/s/files/1/0413/6779/7922/products/JR_Vienna_New_7_5844f6be-ff48-486a-b3d4-0d16c652dc37_1800x1800_1_1200x1200.jpg?v=1642101968" />
                  <div className="vid-item-detail">
                    <div className="vid-title">
                      <div className="author-name">
                        <span className="auth-image">
                          <img alt="" src="https://static.konnect.bio/banner/07541cb3-e242-4f29-a4c1-2d7a5efb166a.jpg" />
                        </span>
                        <span className="auth-title">Judith Ripka</span>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
            <div className="event-col-box">
              <NavLink to="/900000496" className="live-vid-link">
                <div className="vid-item">
                  <img alt="" src="https://media1.popsugar-assets.com/files/thumbor/HDjdstm17IyKfb4D4dp21SZiHBw/fit-in/2048xorig/filters:format_auto-!!-:strip_icc-!!-/2018/08/29/701/n/44285655/1e399895360866ee_AdidasOriginal_FW18_Falcon_B28126_Look2_FullBody_0190_03/i/Kylie-Jenner-Adidas-Falcon-Sneakers-2018.jpg" />
                  <div className="vid-item-detail">
                    <div className="vid-title">
                      <div className="author-name">
                        <span className="auth-image">
                          <img alt="" src="https://static.konnect.bio/banner/98a0b149-cf5f-4f0f-afbf-461bed6c25b1.jpg" />
                        </span>
                        <span className="auth-title">Adidas</span>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
}
