import Layout from "./component/layout";
import All from "./component/all";
export default ({socialStore}) => {
	return (
		<>
			<Layout socialStore={socialStore}>
				<All />
			</Layout>
		</>
	);
};
