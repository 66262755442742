import { GET_RETRIEVE_POST_REQUEST, GET_RETRIEVE_POST_SUCCESS, GET_RETRIEVE_POST_ERROR, GET_REVISE_POST_REQUEST, GET_REVISE_POST_SUCCESS, GET_REVISE_POST_ERROR, POST_REMOVE_REQUEST, POST_REMOVE_SUCCESS, POST_REMOVE_ERROR, POST_RESERVE_REQUEST, POST_RESERVE_SUCCESS, POST_RESERVE_ERROR, POST_PUBLISH_REQUEST, POST_PUBLISH_SUCCESS, POST_PUBLISH_ERROR, POST_RECEIVE_REQUEST, POST_RECEIVE_SUCCESS, POST_RECEIVE_ERROR, POST_REVISE_STATUS_REQUEST, POST_REVISE_STATUS_SUCCESS, POST_REVISE_STATUS_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";
export const retrievePost = (media_id, token) => (dispatch) => {
  dispatch({ type: GET_RETRIEVE_POST_REQUEST });
  return axios({
    method: "GET",
    url: `${BASEURL}posts/retrieve/${media_id}`,
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return dispatch({
        type: GET_RETRIEVE_POST_SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_RETRIEVE_POST_ERROR,
        payload: error.response,
      });
    });
};

export const revisePost = (item, token) => (dispatch) => {
  dispatch({ type: GET_REVISE_POST_REQUEST });
  return axios({
    method: "PUT",
    url: `${BASEURL}posts/revise/${item.id}`,
    headers: { Authorization: `Bearer ${token}` },
    data: item,
  })
    .then((response) => {
      return dispatch({
        type: GET_REVISE_POST_SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_REVISE_POST_ERROR,
        payload: error.response,
      });
    });
};

export const removePost = (id, token) => (dispatch) => {
  dispatch({ type: POST_REMOVE_REQUEST });
  return axios({
    method: "DELETE",
    url: `${BASEURL}posts/remove/${id}`,
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return dispatch({
        type: POST_REMOVE_SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      return dispatch({
        type: POST_REMOVE_ERROR,
        payload: error.response,
      });
    });
};

export const reservePost = (item, token) => (dispatch) => {
  dispatch({ type: POST_RESERVE_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}posts/reserve`,
    headers: { Authorization: `Bearer ${token}` },
    data: item,
  })
    .then((response) => {
      return dispatch({
        type: POST_RESERVE_SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      return dispatch({
        type: POST_RESERVE_ERROR,
        payload: error.response,
      });
    });
};

export const publishPost = (token, id) => (dispatch) => {
  dispatch({ type: POST_PUBLISH_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}posts/publish/${id}`,
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return dispatch({
        type: POST_PUBLISH_SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      return dispatch({
        type: POST_PUBLISH_ERROR,
        payload: error.response,
      });
    });
};

export const receiveBrandPost =
  (token, page, limit = 18) =>
  (dispatch) => {
    dispatch({ type: POST_RECEIVE_REQUEST });
    return axios({
      method: "GET",
      url: `${BASEURL}posts/receive/brand?page=${page}&limit=${limit}`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        return dispatch({
          type: POST_RECEIVE_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        return dispatch({
          type: POST_RECEIVE_ERROR,
          payload: error.response,
        });
      });
  };

export const reviseStatus = (token, post_id, status) => (dispatch) => {
  dispatch({ type: POST_REVISE_STATUS_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}posts/revise/status `,
    headers: { Authorization: `Bearer ${token}` },
    data: { post_id, status },
  })
    .then((response) => {
      return dispatch({
        type: POST_REVISE_STATUS_SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      return dispatch({
        type: POST_REVISE_STATUS_ERROR,
        payload: error.response,
      });
    });
};
