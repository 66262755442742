import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import { Select } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPeopleRoof } from "@fortawesome/free-solid-svg-icons";
import { DownOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import outSideClick from "../../../helper/outSideClick";
import { getBioShopMenu } from "../../../redux/actions/bioShop.action";
//import { getSubCategories } from "../../../redux/actions/category.action";
import "react-multi-carousel/lib/styles.css";
import "../../../css/bioshop.scss";

const { Option } = Select;
const userInfo = JSON.parse(localStorage.getItem("userInfo"));
export default ({ socialStore, children, setFilter }) => {
	const [subCategory, setSubCategory] = useState([]);
	const dispatch = useDispatch();
	const { bioShopMenu } = useSelector((state) => {
		return state;
	});
	const params = useParams();
	const componentWillUnmount = useRef(false);
	const [displayCategory, setDisplayCategory] = useState(false);
	const divRef = useRef();
	const handler = useCallback(() => setDisplayCategory(false), []);
	const [categoryName, setCategoryName] = useState("");
	const [activeCategory, setActiveCategory] = useState("");
	const [activeSubCategory, setActiveSubCateogry] = useState("");
	const [filterPost, setFilterPost] = useState({
		categoryId: "",
		subCategoryId: "",
		postType: "image,campaign,video",
		sortBy: "",
	});
	outSideClick(divRef, handler);
	useEffect(() => {
		//alert(socialStore)
		document.body.classList.add("bioshop-body");
		dispatch(getBioShopMenu(params.username, userInfo?.token));
	}, [params.username]);

	// This is componentWillUnmount
	useEffect(() => {
		return () => {
			componentWillUnmount.current = true;
			document.body.classList.remove("bioshop-body");
		};
	}, []);

	function ComponentBioShop() {
		return (
			<div className="bio_shop_body">
				<div className="bio_shop_header">
					<div className="bio_shop_header_left">
						{bioShopMenu?.payload?.message?.data?.type === "brand" && (
							<div className="brand-descrp">
								<div className="brand_logo">
									<img src={bioShopMenu?.payload?.message?.data?.profile_image_url} />
								</div>
								<div className="current-brand-name">{bioShopMenu?.payload?.message?.data?.name}</div>
							</div>
						)}
						{bioShopMenu?.payload?.message?.data?.promo !== "KB0" && bioShopMenu?.payload?.message?.data?.promo && <div className="brand_name">GET {bioShopMenu?.payload?.message?.data?.discount} OFF USING KBSHOP APP</div>}
					</div>
				</div>
				<div className="bioshop_filters_new">
					<div ref={divRef} className="post-type category-filter">
						<label>Categories</label>
						<span
							className="category-box"
							onClick={() => {
								if (!displayCategory) setDisplayCategory(true);
								else setDisplayCategory(false);
							}}
						>
							{categoryName ? categoryName : "All Categories"} <DownOutlined />
						</span>
						{displayCategory && (
							<div className="category-box-all">
								<div className="all-inner">
									<div className="main-cat-left">
										<h3>Categories</h3>
										<div className="cat-filter-box">
											<button
												onClick={() => {
													setFilterPost({
														...filterPost,
														categoryId: "",
														parentId: [],
													});
													setFilter({
														...filterPost,
														categoryId: "",
														parentId: [],
													});
													setActiveCategory("");
													setCategoryName("");
													setActiveSubCateogry("");
													setSubCategory([]);
												}}
												className={activeCategory === "" && "active"}
											>
												All
											</button>
											{bioShopMenu?.payload?.message?.data?.categories.map((item, i) => {
												return (
													<button
														className={activeCategory === item.parent_id && "active"}
														onClick={() => {
															if (activeCategory === item.parent_id) {
																setActiveCategory("");

																setFilterPost({
																	...filterPost,
																	categoryId: "",
																	parentId: [],
																	subCategoryId: "",
																});
																setFilter({
																	...filterPost,
																	categoryId: "",
																	parentId: [],
																	subCategoryId: "",
																});
															} else {
																setCategoryName(item?.category_name);
																setActiveCategory(item.parent_id);
																setFilterPost({
																	...filterPost,
																	categoryId: item.category_id,
																	parentId: [item.parent_id],
																	subCategoryId: "",
																});
																setFilter({
																	...filterPost,
																	categoryId: item.category_id,
																	parentId: [item.parent_id],
																	subCategoryId: "",
																});
															}
															// dispatch(getSubCategories(item?.parent_id)).then((res) => {
															// 	setSubCategory(res?.payload?.data);
															// });
														}}
														key={i}
													>
														{item?.category_name}
													</button>
												);
											})}
										</div>
									</div>
									{subCategory?.length > 0 && (
										<div className="main-cat-right">
											<h3>Subcategories</h3>
											<div className="cat-filter-box">
												{subCategory?.map((item, key) => {
													return (
														<button
															className={activeSubCategory === item._id && "active"}
															onClick={() => {
																if (activeSubCategory === item._id) {
																	setActiveSubCateogry("");
																	setFilterPost({
																		...filterPost,
																		subCategoryId: "",
																	});
																	setFilter({
																		...filterPost,
																		subCategoryId: "",
																	});
																} else {
																	setCategoryName(item?.category_name);
																	setActiveSubCateogry(item._id);
																	setFilterPost({
																		...filterPost,
																		subCategoryId: item._id,
																	});
																	setFilter({
																		...filterPost,
																		subCategoryId: item._id,
																	});
																}
															}}
															key={key}
														>
															{item?.category_name}
														</button>
													);
												})}
											</div>
										</div>
									)}
								</div>
							</div>
						)}
					</div>

					<div className="post-type">
						<label>Sort By</label>
						<Select
							onChange={(sortBy) => {
								setFilterPost({ ...filterPost, sortBy: sortBy });
								setFilter({ ...filterPost, sortBy: sortBy });
							}}
							size="large"
							defaultValue=""
						>
							<Option value="">Date</Option>
							<Option value="topdiscount">Top Discount %</Option>
							<Option value="referralfee">Referral Fee</Option>
							<Option value="influencerfee">Influencer Fee</Option>
						</Select>
					</div>
					<div className="post-type"></div>
					<div className="post-type show-live-events">
						<Link to={`/${params.username}`}>
							<FontAwesomeIcon icon={faPeopleRoof} /> Social Store
						</Link>
					</div>
				</div>
				{children}
			</div>
		);
	}
	return (
		<>
			<div className={`${!socialStore ? "bio_shop_main_ift" : ""}`}>
				<div className={`${!socialStore ? "midd-width-ifti" : ""}`}>
					<div className={`${!socialStore ? "bio_container_main" : ""}`}>{ComponentBioShop()}</div>
				</div>
			</div>
		</>
	);
};