import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { LoadingOutlined } from "@ant-design/icons";
import { Select, DatePicker, Input, InputNumber, Form, Button, Spin } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { instaMedia, ignextMedia } from "../../../redux/actions/instagram.action";
import { getCategoriesSetup, subCategories } from "../../../redux/actions/category.action";
import { galleryPost } from "../../../redux/actions/gallery.action";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import BioShop from "../../bioShop/index";
import ImageModal from "../component/imageModal";
import VideoModal from "../component/videoModal";
import Swal from "sweetalert2";
import "react-multi-carousel/lib/styles.css";
import "../../../css/bioshop.scss";
import { reservePost, retrievePost, removePost, revisePost, receiveBrandPost } from "../../../redux/actions/postRetrieve.action";
let userInfo = JSON.parse(localStorage.getItem("userInfo"));
var imgDataSet = [];
var promoList;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
export default ({ socialStore }) => {
	const dispatch = useDispatch();
	const { account, categoriesSetup, subCategoryig, reservedPost, retrievedPost, removedPost, revisedPost, instagramMedia } = useSelector((state) => {
		return state;
	});
	const [chatWidth, setChatWidth] = useState(undefined);
	const [sidebarTop, setSidebarTop] = useState(undefined);
	const [postItem, setPostItem] = useState(false);
	const [post, setPost] = useState([]);
	const [pageData, setPageData] = useState([]);
	const [update, setUpdate] = useState(false);
	//
	const [modal, setModal] = useState(false);

  const startDay = moment();
  const endDay = moment().add(1, "years");

	const [startDate, setStartDate] = useState(startDay);
	const [endDate, setEndDate] = useState(endDay);
	const [category, setCategory] = useState([]);
	//Image Modal
	const [galleryPosts, setGalleryPosts] = useState([]);
	const [brandPosts, setBrandPosts] = useState([]);
	const [source, setSource] = useState("");
	const [brandPage, setBrandPage] = useState(1);
	const [form] = Form.useForm();
	const params = useParams();

	useEffect(() => {
		dispatch(getCategoriesSetup(userInfo?._id));
		dispatch(receiveBrandPost(userInfo?.token, brandPage)).then((res) => {
			setBrandPosts(
				res?.payload?.data?.message?.data.map((item) => {
					return { ...item, post_id: item._id };
				})
			);
			setBrandPage(res?.payload?.data?.message?.next?.page);
		});
		dispatch(galleryPost()).then((res) => {
			setGalleryPosts(res?.payload?.data?.message?.data);
		});
		if (account) {
			dispatch(instaMedia(params.username, params.token)).then((res) => {
				setPost(res?.payload?.data.data);
				setPageData(res?.payload?.data?.paging?.next);
			});
		}
	}, []);

  useEffect(() => {
    document.body.classList.add("bioshop-body", "influencer-bioshop");
  }, [postItem]);

  useEffect(() => {
    return () => {
      document.body.classList.remove("bioshop-body", "influencer-bioshop");
    };
  }, []);

  useEffect(() => {
    const chatEl = document.querySelector(".sidebar").getBoundingClientRect();
    setChatWidth(chatEl.width);
    setSidebarTop(chatEl.top);
  }, []);

  useEffect(() => {
    if (!sidebarTop) return;
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, [sidebarTop]);

  useEffect(() => {
    if (update && postItem) {
      if (postItem?.categories) {
        dispatch(subCategories(postItem?.categories[0]?.parent_id));
        setCategory(postItem?.categories[0]?.parent_id);
        form.setFieldsValue({
          category: postItem?.categories[0]?.parent_id,
          subCategory: postItem?.sub_categories[0]?.sub_category_id,
          date: [moment(postItem?.start_date), moment(postItem?.start_date)],
        });
        setStartDate(moment(postItem?.start_date));
        setEndDate(moment(postItem?.end_date));
      }
    }

    if (update === false) {
      form.setFieldsValue({
        destination: "ecommerce",
        category: null,
        subCategory: null,
        date: [moment(startDay), moment(endDay)],
      });

      setStartDate(startDay);
      setEndDate(endDay);
    }
  }, [postItem]);

  const isSticky = (e) => {
    const chatEl = document.querySelector(".sidebar");
    const scrollTop = window.scrollY;
    if (scrollTop >= sidebarTop - 10) {
      chatEl.classList.add("is-sticky");
    } else {
      chatEl.classList.remove("is-sticky");
    }
  };

  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY-MM-DD";

	const onFinish = (values) => {
		if (imgDataSet.length === 0) {
			Swal.fire({
				title: `${postItem.media_type === "VIDEO" ? "Please Add the video Link" : "Tag at least one Image"}`,
				icon: "warning",
				confirmButtonColor: "#010b40",
				confirmButtonText: `Ok`,
			});
			return;
		}

		let categoryId = categoriesSetup?.payload?.message?.filter((item) => item?.parent_id === values.category).map((item) => item.category_id);
		let item = {
			id: update ? postItem.post_id : postItem?.id,
			media_url: postItem?.media_url,
			media_type: postItem?.media_type,
			timestamp: postItem?.timestamp,
			redirected_url: "http://",
			username: postItem?.username,
			categories: categoryId,
			sub_categories: [values.subCategory],
			post_type: postItem?.media_type === "VIDEO" ? "video" : "image",
			start_date: moment.utc(startDate).local().toISOString(),
			end_date: moment.utc(endDate),
			source: source === "mediaGallery" ? "gallery" : "instagram",
			product_source: "other",
			promo: "",
			discount: "",
			description: "",
			amount: 0,
			children: imgDataSet,
		};

		if (update) {
			dispatch(revisePost(item, userInfo?.token)).then(() => {
				if (source === "mediaGallery") {
					dispatch(galleryPost()).then((res) => {
						setPost(res?.payload?.data?.message?.data);
						setPostItem(false);
					});
				} else {
					dispatch(instaMedia(params.username, params.token)).then((res) => {
						setPost(res?.payload?.data.data);
						setPageData(res?.payload?.data?.paging?.next);
						setPostItem(false);
					});
				}
			});
		} else {
			dispatch(reservePost(item, userInfo?.token)).then(() => {
				if (source === "mediaGallery") {
					dispatch(galleryPost()).then((res) => {
						setPost(res?.payload?.data?.message?.data);
						setPostItem(false);
					});
				} else {
					dispatch(instaMedia(params.username, params.token)).then((res) => {
						setPost(res?.payload?.data.data);
						setPageData(res?.payload?.data?.paging?.next);
						setPostItem(false);
					});
				}
			});
		}
	};
	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	const fetchData = async () => {
		if (source === "brand") {
			if (brandPage) {
				return dispatch(receiveBrandPost(userInfo?.token, brandPage)).then((res) => {
					let postData = res?.payload?.data?.message?.data.map((item) => {
						return { ...item, post_id: item._id };
					});
					setBrandPosts([...post, ...postData]);
					setBrandPage(res?.payload?.data?.message?.next?.page);
					setPost([...post, ...postData]);
				});
			}
		} else {
			return dispatch(ignextMedia(post[0].username, pageData)).then((postData) => {
				setPageData(postData?.payload?.data?.paging?.next);
				setPost([...post, ...postData?.payload?.data?.data]);
			});
		}
	};

  const changeCategory = async (category) => {
    form.resetFields(["subCategory"]);
    setCategory(category);
    dispatch(subCategories(category));
  };

  function dateRangePickerChanger(value, dataString) {
    let startDate = dataString[0];
    let endDate = dataString[1];
    setStartDate(startDate);
    setEndDate(endDate);
  }

	const imgData = (data) => {
		imgDataSet = data;
	};
	const handleChange = (value) => {
		setSource(value);
		if (value === "mediaGallery") {
			setPost(galleryPosts);
		} else if (value === "brand") {
			setPageData(brandPage)
			setPost(brandPosts);
		} else {
			setPageData(instagramMedia?.payload?.data?.paging?.next)
			setPost(instagramMedia?.payload?.data?.data);
		}
		setPostItem(false);
		setUpdate(false);
	};
	return (
		<>
			<div className="bio_shop_main_ift">
				<div className="midd-width-ifti">
					<div className="bio_container_main bio_container_main_infl row">
						<div className="left-column col-12">
							<div className="select-source-container">
								<div className="select-source">
									<Select
										size="large"
										defaultValue="Source: Instagram @dl1961denim"
										style={{
											width: "100%",
										}}
										onChange={handleChange}
										options={[
											{
												value: params.username,
												label: `Source: Instagram @${params.username}`,
											},
											{
												value: "mediaGallery",
												label: "Active Media Gallery",
											},
											// {
											// 	value: "brand",
											// 	label: "Brand",
											// },
										]}
									/>
								</div>
								<span className="connected-button">Connected</span>
							</div>
							<div className="sidebar" style={{ width: chatWidth }}>
								<div className="mobile-preview">
									<div className="preview-mobile-box">
										<div className="mobile-header">
											<span className="place-holder-name">{params.username}</span>
										</div>
										<div className="mobile-gallery" id="scrollableDiv">
											{instagramMedia.loading && <Spin className="white-loader loader-center" size="large" />}
											<InfiniteScroll
												dataLength={post?.length}
												className="row"
												next={fetchData}
												hasMore={pageData}
												scrollableTarget="scrollableDiv"
												loader={
													<div className={`container`}>
														<div className="d-flex justify-content-center">
															<div className="content-loader"></div>
														</div>
													</div>
												}
												// useWindow={false}
											>
												{" "}
												{post?.map((item, key) => {
													return (
														<>
															{item?.media_type === "VIDEO" ? (
																<div key={key} className="col-4">
																	<div
																		onClick={() => {
																			if (item.linked) {
																				dispatch(retrievePost(item.post_id, userInfo?.token)).then((res) => {
																					setPostItem(res?.payload?.data?.message);
																					setUpdate(true);
																				});
																			} else {
																				setPostItem(item);
																				setUpdate(false);
																			}
																		}}
																		className="mobile-image-box"
																	>
																		<div className="mobile_box_inr">
																			<video className={item.linked || item.select ? "linked" : ""} key={item.id} id={item.id}>
																				<source src={item.media_url} type="video/mp4"></source>
																			</video>
																			{item?.linked && (
																				<span className="linked-label">
																					LINKED <span className="glyphicon glyphicon-link"></span>
																				</span>
																			)}
																		</div>
																		<button className="btn-link btn-play video-label">
																			<i class="fa fa-play" aria-hidden="true"></i>
																		</button>
																	</div>
																</div>
															) : (
																<div key={key} className="col-4">
																	<div
																		onClick={() => {
																			if (item.linked) {
																				dispatch(retrievePost(item.post_id, userInfo?.token)).then((res) => {
																					setPostItem(res?.payload?.data?.message);
																					setUpdate(true);
																				});
																			} else {
																				setPostItem(item);
																				setUpdate(false);
																			}
																		}}
																		className="mobile-image-box"
																	>
																		<div className="mobile_box_inr">
																			<img alt="" src={item?.media_url} />
																			{item?.linked && (
																				<span className="linked-label">
																					LINKED <span className="glyphicon glyphicon-link"></span>
																				</span>
																			)}
																		</div>
																	</div>
																</div>
															)}
														</>
													);
												})}
											</InfiniteScroll>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="right-column bio_shop_body col-12">
							{postItem ? (
								<Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
									<div className="col-12 p-0 add-post-container">
										<div className="image-edit-box">
											<div className="close-box" onClick={() => setPostItem(false)}>
												<i className="fa fa-times" aria-hidden="true"></i>
											</div>

                      <div className="ind-post-anlytics image-box-info">
                        <div className="edit-left">
                          <h4>{!update ? "Add Post" : "Edit Post"}</h4>
                          <p>
                            {!update ? "Posted on " : "Updated on "}
                            {moment.utc(postItem.timestamp).format("MMM Do YYYY")}
                          </p>
                        </div>

                        {update && (
                          <div className="edit-right">
                            <div className="an-col col-md-3">
                              <div className="an-col-inr">
                                <div className="an-content clearfix">
                                  <span class="dash_icon-top">
                                    <i class="fa fa-eye fa-2x" aria-hidden="true"></i>
                                  </span>
                                  <div class="imp-t text-right">{postItem.post_views ? postItem.post_views : 0}</div>
                                  <div class="imp-tx text-uppercase text-muted text-right">IMPRESSIONS</div>
                                </div>
                              </div>
                            </div>
                            <div className="an-col col-md-3">
                              <div className="an-col-inr">
                                <div className="an-content clearfix">
                                  <span class="dash_icon-top">
                                    <i class="fa fa-hand-pointer-o fa-2x" aria-hidden="true"></i>
                                  </span>
                                  <div class="imp-t text-right">{postItem.post_clicks ? postItem.post_clicks : 0}</div>
                                  <div class="imp-tx text-uppercase text-muted text-right">CLICKS</div>
                                </div>
                              </div>
                            </div>
                            <div className="an-col col-md-3">
                              <div className="an-col-inr">
                                <div className="an-content clearfix">
                                  <span class="dash_icon-top">
                                    <i class="fa fa-handshake-o fa-2x" aria-hidden="true"></i>
                                  </span>
                                  <div class="imp-t text-right"> {postItem.ctr ? (postItem.ctr === "NaN" ? 0 : postItem.ctr) : 0} %</div>
                                  <div class="imp-tx text-uppercase text-muted text-right">ENGAGEMENT</div>
                                </div>
                              </div>
                            </div>
                            <div className="an-col col-md-3">
                              <div className="an-col-inr">
                                <div className="an-content clearfix">
                                  <span class="dash_icon-top">
                                    <i class="fa fa-usd fa-2x" aria-hidden="true"></i>
                                  </span>
                                  <div class="imp-t text-right">$0.00</div>
                                  <div class="imp-tx text-uppercase text-muted text-right">REVENUE</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="image-wrapper">
                        <div className="image-box">
                          {retrievedPost.loading ? (
                            <Spin size="large" />
                          ) : (
                            <>
                              {postItem?.media_type === "VIDEO" ? (
                                <VideoModal
                                  media={source}
                                  postItem={postItem}
                                  imgData={imgData}
                                  children={update ? postItem.children : []}
                                  check={[]}
                                  mediaUrl={[]}
                                  selectPost={postItem}
                                  categoryList={[]}
                                  promoList={promoList}
                                  promoLoading={""}
                                  Kbfee={""}
                                  category={category}
                                  subcategory={[]}
                                  source={"other"}
                                  url={""}
                                  promoCode={""}
                                  promoDsc={""}
                                  // setSource={setSource}

                                  updateProduct={update}
                                  mediaType={""}
                                />
                              ) : (
                                <ImageModal
                                  media={source}
                                  postItem={postItem}
                                  imgData={imgData}
                                  children={update ? postItem.children : []}
                                  check={[]}
                                  mediaUrl={[]}
                                  selectPost={postItem}
                                  categoryList={[]}
                                  promoList={promoList}
                                  promoLoading={""}
                                  Kbfee={""}
                                  category={category}
                                  subcategory={[]}
                                  source={"other"}
                                  url={""}
                                  promoCode={""}
                                  promoDsc={""}
                                  // setSource={setSource}
                                  updateProduct={update}
                                  mediaType={""}
                                />
                              )}
                            </>
                          )}
                        </div>
                        <div className="image-edit-links">
                          <div>
                            <label>Select Destination</label>
                            <Form.Item
                              name="destination"
                              rules={[
                                {
                                  message: "Please Enter Destination",
                                },
                              ]}
                            >
                              <Select
                                disabled
                                defaultValue="ecommerce"
                                size="large"
                                style={{
                                  width: "100%",
                                }}
                                options={[
                                  {
                                    value: "ecommerce",
                                    label: "Web Link",
                                  },
                                ]}
                              />
                            </Form.Item>
                          </div>
                          <div className="select-category mt-3">
                            <label>Select Category</label>
                            <Form.Item
                              name="category"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter Category",
                                },
                              ]}
                            >
                              <Select
                                disabled={source === "brand" && true}
                                showSearch
                                size="large"
                                style={{
                                  width: "100%",
                                }}
                                onChange={changeCategory}
                                placeholder="Select Category"
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? "").includes(input)}
                                filterSort={(optionA, optionB) => (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())}
                                options={categoriesSetup?.payload?.message.map((item) => {
                                  return {
                                    value: item.parent_id,
                                    label: item.category_name,
                                  };
                                })}
                              />
                            </Form.Item>
                          </div>
                          {subCategoryig?.payload?.message && (
                            <div className="select-category mt-3">
                              <label>Select SubCategory</label>
                              <Form.Item
                                name="subCategory"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter SubCategory",
                                  },
                                ]}
                              >
                                <Select
                                  disabled={source === "brand" && true}
                                  showSearch
                                  size="large"
                                  style={{
                                    width: "100%",
                                  }}
                                  placeholder="Select Sub Category"
                                  optionFilterProp="children"
                                  filterOption={(input, option) => (option?.label ?? "").includes(input)}
                                  filterSort={(optionA, optionB) => (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())}
                                  options={subCategoryig?.payload?.message.map((item) => {
                                    return {
                                      value: item.sub_category_id,
                                      label: item.sub_category_name,
                                    };
                                  })}
                                />
                              </Form.Item>
                            </div>
                          )}

                          <div className="date-range mt-3">
                            <label>Start Date / End Date</label>
                            <Form.Item name="date">
                              <RangePicker
                                disabled={source === "brand" && true}
                                size="large"
                                key={1}
                                defaultValue={[moment(startDate), moment(endDate)]}
                                value={[moment(startDate), moment(endDate)]}
                                defaultPickerValue={moment(new Date(), "YYYY-MM-DD")}
                                disabledDate={(current) => {
                                  let customDate = moment().format("YYYY-MM-DD");
                                  return current && current < moment(customDate, "YYYY-MM-DD");
                                }}
                                allowClear={false}
                                ranges={{
                                  Today: [moment(), moment()],
                                  Tomorrow: [moment().add(1, "days"), moment().add(1, "days")],
                                  "This Month": [moment().startOf("month"), moment().endOf("month")],
                                }}
                                style={{ width: "100%" }}
                                format={dateFormat}
                                onChange={dateRangePickerChanger}
                              />
                            </Form.Item>
                          </div>

                          {postItem.media_type === "VIDEO" && category.length > 0 && (
                            <div className="edit_button_main">
                              <Button
                                onClick={() => {
                                  document.getElementById("videoClick").click();
                                }}
                                type="primary"
                                size="large"
                              >
                                Add Link
                              </Button>
                            </div>
                          )}

                          <div className="edit_button_main">
                            {source !== "brand" && (
                              <>
                                {reservedPost?.loading || revisedPost?.loading ? (
                                  <Button type="primary" size="large">
                                    <Spin indicator={antIcon} />
                                  </Button>
                                ) : (
                                  <>
                                    <Button htmlType="submit" type="primary" size="large">
                                      {update ? "UPDATE" : "SAVE"}
                                    </Button>
                                  </>
                                )}
                              </>
                            )}
                            <Button onClick={() => setPostItem(false)} type="primary" size="large">
                              CANCEL
                            </Button>

														{update && (
															<>
																{removedPost.loading ? (
																	<Button type="primary" size="large">
																		<Spin indicator={antIcon} />
																	</Button>
																) : (
																	<Button
																		onClick={() => {
																			const swalWithBootstrapButtons = Swal.mixin({
																				customClass: {
																					confirmButton: "btn btn-danger",
																					cancelButton: "btn btn-success",
																				},
																				buttonsStyling: false,
																			});
																			swalWithBootstrapButtons
																				.fire({
																					title: "Delete Post",
																					icon: "warning",
																					text: "Are you sure you want to delete?",
																					//	imageUrl: item?.postData?.media_url,
																					imageWidth: 100,
																					imageHeight: 100,
																					//	imageAlt: item?.name,
																					showCancelButton: true,
																					confirmButtonText: "Delete!",
																					cancelButtonText: "Cancel",
																					reverseButtons: true,
																				})
																				.then((result) => {
																					if (result.isConfirmed) {
																						dispatch(removePost(postItem.post_id, userInfo?.token)).then(() => {
																							if (source === "mediaGallery") {
																								dispatch(galleryPost()).then((res) => {
																									setPost(res?.payload?.data?.message?.data);
																									setPostItem(false);
																									setUpdate(false);
																									Swal.fire({
																										icon: "success",
																										title: "Post deleted successfully!",
																									});
																								});
																							} else if (source === "brand") {
																								dispatch(receiveBrandPost(userInfo?.token)).then((res) => {
																									setBrandPosts(
																										res?.payload?.data?.message?.data.map((item) => {
																											return { ...item, post_id: item._id };
																										})
																									);
																									setPost(
																										res?.payload?.data?.message?.data.map((item) => {
																											return { ...item, post_id: item._id };
																										})
																									);
																									setPostItem(false);
																									setUpdate(false);
																									Swal.fire({
																										icon: "success",
																										title: "Post deleted successfully!",
																									});
																								});
																							} else {
																								dispatch(instaMedia(params.username, params.token)).then((res) => {
																									setPost(res?.payload?.data.data);
																									setPageData(res?.payload?.data?.paging?.next);
																									setPostItem(false);
																									setUpdate(false);
																									Swal.fire({
																										icon: "success",
																										title: "Post deleted successfully!",
																									});
																								});
																							}
																						});
																					}
																				});
																		}}
																		type="primary"
																		size="large"
																	>
																		REMOVE
																	</Button>
																)}
															</>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</Form>
							) : (
								<BioShop socialStore={socialStore} />
							)}
						</div>
					</div>
				</div>
			</div>
			<Modal dialogClassName="post-modal" scrollable show={modal} onHide={() => setModal(false)} centered>
				<Modal.Header closeButton>
					<h4>Add Web Link</h4>
				</Modal.Header>
				<Modal.Body className="pl-0 pr-0">
					<Form
						name="basic"
						layout="vertical"
						labelCol={{
							span: 24,
						}}
						wrapperCol={{
							span: 24,
						}}
						initialValues={{
							remember: true,
						}}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						autoComplete="off"
					>
						<div className="d-flex web-link-form">
							<div className="sku-image-box col-md-4">
								<Button type="primary" size="large">
									Upload Image
								</Button>
							</div>
							<div className="col-md-8">
								<div className="mb-3">
									<Form.Item
										label="Enter URL"
										name=""
										rules={[
											{
												required: false,
												message: "Please input your username!",
											},
										]}
									>
										<Input type="url" placeholder="Enter URL" size="large" />
									</Form.Item>
								</div>
								<div className="mb-3">
									<Form.Item
										label="Enter SKU"
										name=""
										rules={[
											{
												required: false,
												message: "Please input your username!",
											},
										]}
									>
										<InputNumber placeholder="Enter SKU" size="large" />
									</Form.Item>
								</div>
								<div className="mb-3">
									<Form.Item
										label="Enter Product Name"
										name=""
										rules={[
											{
												required: false,
												message: "Please input your username!",
											},
										]}
									>
										<Input placeholder="Enter Product Name" size="large" />
									</Form.Item>
								</div>
								<div className="row mb-3">
									<div className="col-md-6 ">
										<Form.Item
											label="Amount"
											name=""
											rules={[
												{
													required: false,
													message: "Please input your username!",
												},
											]}
										>
											<InputNumber addonBefore="$" size="large" className="dollar-radius" />
										</Form.Item>
									</div>
									<div className="col-md-6 ">
										<Form.Item
											label="Enter Description"
											name=""
											rules={[
												{
													required: false,
													message: "Please input your username!",
												},
											]}
										>
											<Input placeholder="Enter Description" size="large" />
										</Form.Item>
									</div>
								</div>

                <div className="mb-3">
                  <Button type="primary" size="large" className="w-100">
                    SAVE
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
