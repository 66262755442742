import React, { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { getAllUpComingEvent } from "../../redux/actions/liveShopping.action";
import { dateInDays } from "../../helper/date";


export default function UpComingEvent({ category, source,sortBy }) {
  const dispatch = useDispatch();
  const [count, setCount] = useState(2);
  const sliderRef = useRef();
  const [data, setData] = useState([]);
  const { allUpcomingEvent, account, brand, bioShopMenu } = useSelector((state) => {
    return state;
  });

  let userInfo = localStorage.getItem("userInfo");

  if (userInfo !== "undefined") {
    userInfo = JSON.parse(localStorage.getItem("userInfo"));
  } else {
    localStorage.removeItem("userInfo");
    window.location.reload();
  }

  useEffect(() => {
    if (brand?.payload?.success || source === "bioshop") {
      dispatch(getAllUpComingEvent(1, 30, category, source === "bioshop" ? bioShopMenu?.payload?.message?.data._id : brand?.payload?.message,sortBy)).then((res) => {
        setData(res?.payload?.message?.data);
      });
    }
  }, [category,sortBy]);

  const liveEvent = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 5.5,
    swipe: false,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow className={"slick-arrow slick-next"} />,
    prevArrow: <SamplePrevArrow className={"slick-arrow slick-next"} />,

    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 4.2,
          swipe: true,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 3.2,
          swipe: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
          swipe: true,
        },
      },
    ],
  };

  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <button
        className={className}
        onClick={() => {
          setCount(() => count + 1);
          onClick();
          if (allUpcomingEvent?.payload?.message?.next) {
            dispatch(getAllUpComingEvent(count, 30, category, source === "bioshop" ? bioShopMenu?.payload?.message?.data._id : brand?.payload?.message,sortBy))
              .then((res) => {
                setData([...data, ...res?.payload?.message?.data]);
              })
              .then(() => {
                sliderRef.current.slickGoTo(1);
              });
          }
        }}
      ></button>
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <button
        className={className}
        onClick={() => {
          if (count > 0) setCount(() => count - 1);
          onClick();
        }}
      ></button>
    );
  }

	const onSwipeEvent = (swipeDirection) => {
		if (swipeDirection === "left") {
			if (allUpcomingEvent?.payload?.message?.next) {
				dispatch(getAllUpComingEvent(count, 30, category, source === "bioshop" ? bioShopMenu?.payload?.message?.data._id : brand?.payload?.message,sortBy)).then((res) => {
					setData([...data, ...res?.payload?.message?.data]);
				});
			}
		}
	};
	return (
		<>
			<div className={`event-container ${!allUpcomingEvent?.loading && data.length === 0 && "d-none"}`}>
				<div className="event-header">
					<h3>Upcoming Events</h3>
				</div>
				{allUpcomingEvent?.loading ? (
					<>
						<Slider onSwipe={onSwipeEvent} {...liveEvent}>
							{new Array(6).fill().map((_, i) => {
								return <div className="event-col-box"></div>;
							})}
						</Slider>
					</>
				) : (
					data.length > 0 && (
						<Slider onSwipe={onSwipeEvent} {...liveEvent} ref={sliderRef}>
							{data.map((item) => {
								return (
									<div className="event-col-box">
										<NavLink to={`/upcoming-event/${item?._id}/${item.instagram_username ? item.instagram_username : item.pixel_id}`} className="live-vid-link">
											<div className={`vid-item ${item?.default_banner ? "default-image" : ""}`}>
												<img alt="" src={item?.banner} />
												<div className="vid-item-detail">
													<div className="vid-time">
														<span>in {dateInDays(item?.start_date)}</span>
														{item.discount !== "0%" && item.discount ? <span className="off-percentage">{item.discount} Off</span> : ""}
													</div>
													<div className="vid-title">
														<span className="v-title">{item?.title}</span>
														<div className="author-name justify-content-between">
															{item?.brand_profile ? (
																<div className="auth-left">
																	<span className="auth-image">
																		<img alt="" src={item?.brand_profile} />
																	</span>
																	<span className="auth-title">{item?.brand_name}</span>
																</div>
															) : (
																<>
																	<div className="auth-left">
																		<span className="auth-title">{item?.brand_name}</span>
																	</div>
																</>
															)}

                              <div className="auth-right">
                                {userInfo && account?.payload?.Data?.account_type !== "customer" ? item?.influencer_percent !== "0" && item?.influencer_percent !== "undefined" && item.influencer_percent ? <span className="inf-fee">{item?.influencer_percent}% Influencer Fee</span> : "" : ""}
                                {userInfo?.account_type !== "influencer" ? item?.referral_percent !== "0" && item.referral_percent !== "undefined" && item.referral_percent ? <span className="ref-fee">{item?.referral_percent}% Referral Fee</span> : "" : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                );
              })}
            </Slider>
          )
        )}
      </div>
    </>
  );
}