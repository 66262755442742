import { GET_INSTAGRAM_URL_REQUEST, GET_INSTAGRAM_URL_SUCCESS, GET_INSTAGRAM_URL_ERROR, GET_INSTAGRAM_DATA_REQUEST, GET_INSTAGRAM_DATA_SUCCESS, GET_INSTAGRAM_DATA_ERROR, UPDATE_ACCESS_TOKEN_REQUEST, UPDATE_ACCESS_TOKEN_SUCCESS, UPDATE_ACCESS_TOKEN_ERROR, DISCONNECT_INSTAGRAM_REQUEST, DISCONNECT_INSTAGRAM_SUCCESS, DISCONNECT_INSTAGRAM_ERROR, GET_REFRESH_INSTAGRAM_REQUEST, GET_REFRESH_INSTAGRAM_SUCCESS, GET_REFRESH_INSTAGRAM_ERROR, GET_INSTAGRAM_MEDIA_REQUEST, GET_INSTAGRAM_MEDIA_SUCCESS, GET_INSTAGRAM_MEDIA_ERROR, GET_INSTAGRAM_NEXTMEDIA_REQUEST, GET_INSTAGRAM_NEXTMEDIA_SUCCESS, GET_INSTAGRAM_NEXTMEDIA_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const getInstagramUrl = () => (dispatch) => {
	dispatch({ type: GET_INSTAGRAM_URL_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}social/ig/url/instagram`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
		data: {
			source: process.env.REACT_APP_BRAND_ID,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_INSTAGRAM_URL_SUCCESS,
				payload: response,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_INSTAGRAM_URL_ERROR,
				payload: error.response,
			});
		});
};

export const getInstagramData = (code, email) => (dispatch) => {
	dispatch({ type: GET_INSTAGRAM_DATA_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}social/ig/data/${code}`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
		data: {
			email: email,
			source: process.env.REACT_APP_BRAND_ID,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_INSTAGRAM_DATA_SUCCESS,
				payload: response,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_INSTAGRAM_DATA_ERROR,
				payload: error.response,
			});
		});
};

export const updateAccessToken = (userId, username, accessToken) => (dispatch) => {
	dispatch({ type: UPDATE_ACCESS_TOKEN_REQUEST });
	return axios({
		method: "PUT",
		url: `${BASEURL}users/revise/ig/instagram`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
		data: {
			user_id: userId,
			username: username,
			access_token: accessToken,
		},
	})
		.then((response) => {
			return dispatch({
				type: UPDATE_ACCESS_TOKEN_SUCCESS,
				payload: response,
			});
		})
		.catch((error) => {
			return dispatch({
				type: UPDATE_ACCESS_TOKEN_ERROR,
				payload: error.response,
			});
		});
};

export const disconnectInstagram =
	(id = "") =>
	(dispatch) => {
		dispatch({ type: DISCONNECT_INSTAGRAM_REQUEST });
		return axios({
			method: "PUT",
			url: `${BASEURL}users/revise/disconnectinstagram/${id}`,
			headers: { Authorization: `Bearer ${userInfo?.token}` },
		})
			.then((response) => {
				return dispatch({
					type: DISCONNECT_INSTAGRAM_SUCCESS,
					payload: response,
				});
			})
			.catch((error) => {
				return dispatch({
					type: DISCONNECT_INSTAGRAM_ERROR,
					payload: error.response,
				});
			});
	};

export const refreshInstagram = () => (dispatch) => {
	dispatch({ type: GET_REFRESH_INSTAGRAM_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}social/ig/refreshinstagramtoken`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
	})
		.then((response) => {
			return dispatch({
				type: GET_REFRESH_INSTAGRAM_SUCCESS,
				payload: response,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_REFRESH_INSTAGRAM_ERROR,
				payload: error.response,
			});
		});
};

export const instaMedia = (username,accessToken,) => (dispatch) => {
	dispatch({ type: GET_INSTAGRAM_MEDIA_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}social/ig/media/${accessToken ? accessToken : userInfo?.access_token}`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
		data: { username: username ? username : userInfo?.username },
	})
		.then((response) => {
			return dispatch({
				type: GET_INSTAGRAM_MEDIA_SUCCESS,
				payload: response,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_INSTAGRAM_MEDIA_ERROR,
				payload: error.response,
			});
		});
};

export const ignextMedia = (username, url) => (dispatch) => {
	dispatch({ type: GET_INSTAGRAM_NEXTMEDIA_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}social/ig/nextMedia/${username}`,
		headers: { Authorization: `Bearer ${userInfo?.token}` },
		data: { url },
	})
		.then((response) => {
			return dispatch({
				type: GET_INSTAGRAM_NEXTMEDIA_SUCCESS,
				payload: response,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_INSTAGRAM_NEXTMEDIA_ERROR,
				payload: error.response,
			});
		});
};
