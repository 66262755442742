import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import inficon from "../../../images/brand-logos/hOuVCU.png";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { notification } from "antd";
import queryString from "query-string";
import { AppstoreOutlined } from "@ant-design/icons";
import BecomeInfluenerModal from "../../../pages/becomeInfluencer/becomeInfluencer";
import { getInstagramUrl, disconnectInstagram, getInstagramData, updateAccessToken } from "../../../redux/actions/instagram.action";
import { getAccount } from "../../../redux/actions/account.action";
import { Button } from "antd";
import Swal from "sweetalert2";

export default function BecomeInfluncer() {
	const [becomeInfluencerModal, setBecomeInfluencerModal] = useState(false);
	const userInfo = JSON.parse(localStorage.getItem("userInfo"));
	const [disconnectModal, setDisconnectModal] = useState(false);
	const [url, setUrl] = useState("");
	const queryParams = queryString.parse(window.location.search);
	const [disconnectLoading, setDisconnectLoading] = useState(false);
	const dispatch = useDispatch();
	const history = useNavigate();

	let query = new URL(window.location.href);

	const { account } = useSelector((state) => {
		return state;
	});

	useEffect(() => {
		if (userInfo?.token && account?.length === 0) dispatch(getAccount(userInfo?.token));
	}, []);

	const disconnect = () => {
		setDisconnectLoading(true);
		dispatch(disconnectInstagram(userInfo?._id)).then((res) => {
			if (res.type == "DISCONNECT_INSTAGRAM_SUCCESS") {
				setDisconnectLoading(false);
				setDisconnectModal(false);
				dispatch(getAccount(userInfo?.token));
			} else {
				setDisconnectLoading(false);
				notification.error({
					message: "Something went wrong!",
					className: "toast-error",
				});
			}
		});
	};

	useEffect(() => {
		if ((queryParams?.code !== undefined && queryParams?.code.length) || userInfo?.instagram_token) {
			dispatch(getInstagramData(userInfo?.instagram_token ? userInfo.instagram_token : queryParams.code, userInfo?.email)).then((res) => {
				if (res.type == "GET_INSTAGRAM_DATA_SUCCESS") {
					dispatch(updateAccessToken(userInfo?._id, res.payload?.data?.username, res.payload?.data?.access_token)).then(() => {
						// window.location.reload();

						history({
							pathname: `/become-influencer`,
						});
						if (queryParams.code !== undefined) {
							dispatch(getAccount(userInfo?.token));
						}
					});
				} else {
					console.log(res.payload.data.message, "fail");

					notification.error({
						message: res?.payload?.data?.message,
						className: "toast-error",
					});
				}
			});
		}
	}, []);

	useEffect(() => {
		dispatch(getInstagramUrl()).then((res) => {
			setUrl(res.payload.data);
		});
	}, []);

	useEffect(() => {
		document.body.classList.add("bioshop-body", "shopper-bioshop-body");
	}, []);

	// This is componentWillUnmount
	useEffect(() => {
		return () => {
			//  componentWillUnmount.current = true;
			document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
		};
	}, []);

	const handleModal = () => {
		Swal.fire({
			title: "Disconnect Instagram",
			text: `Are you sure you want to disconnect @${account?.payload?.Data?.instagram_username} account from kbshop.com? `,
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "No",
			confirmButtonColor: "#010b40",
			cancelButtonColor: "#d33",
			iconHtml: '<i class="fa fa-instagram" aria-hidden="true" />',
			imageWidth: 50,
			imageHeight: 50,
			imageAlt: "instagram image",
			reverseButtons: false,
		}).then((result) => {
			if (result.isConfirmed) {
				disconnect();
			} else {
				setDisconnectModal(false);
			}
		});
	};

	return (
		<>
			<div className="account-left-content">
				<div className="account-detail container">
					<div className="bcm-inf-main">
						<div className="pr-op-block pro-inf-block">
							<div className="inf-cret-main">
								<div className="inf-cret-left">
									{/* <p>
                    Connect your Instagram to become an Influencer. If you have
                    at least <strong>1000</strong> followers, you will be able
                    to manage Social Store once your application is approved.
                  </p> */}
									<p>
										To become an influencer you need to have <strong>1000</strong> followers. Please connect your Instagram and Facebook. <br />
										Once connected you will be able to create a social store and participate in campaigns offered by brands/retailers.
									</p>
									<div className="become-inf-container">
										<p className="app-status">
											Application Status: <strong>{account?.payload?.Data?.became_influencer ? account?.payload?.Data?.became_influencer : "New"}</strong>
										</p>

										{userInfo.account_type !== "brand" && account?.payload?.Data?.became_influencer === "new" && (
											<a
												onClick={() => {
													if (account?.payload?.Data?.became_influencer !== "pending") {
														setBecomeInfluencerModal(true);
													}
												}}
												href="#"
											>
												Become An Influencer
											</a>
										)}
									</div>
								</div>
								<div className="inf-cret-right">
									<img src={inficon} alt="" />
								</div>
							</div>
						</div>
						<div className="row mt-4">
							<div className="col-md-6 mb-20">
								<div className="social_box_main">
									<div className="social_box_header d-flex justify-content-between">
										<h5>Instagram Connection</h5>
										<span className={account?.payload?.Data?.instagram_username  !== "" ? "connection-status-badge-green":"connection-status-badge-red"}>{account?.payload?.Data?.instagram_username !== "" ?  "Connected":"Not Connected"}</span>
									</div>
									<div className="row">
										<div className="col-md-12">
											<div className="dp_fields-setup mb-3">
												<div className="category_count">Connection Status</div>
											</div>
											<div className="dp_fields-setup">
												<div className="connected-text text-left mb-2">
													Connected: <span>@{account?.payload?.Data?.instagram_username}</span>
												</div>

												{account?.payload?.Data?.instagram_username !== "" ? (
													<>
														<Button onClick={handleModal} className="w-100 connect-button-ig-fb">
															<i className="fa fa-instagram" /> &nbsp;Disconnected
														</Button>
													</>
												) : (
													<>
														{account?.payload?.Data?.became_influencer === "approved" ? (
															<Button
																onClick={() => {
																	window.location.replace(url);
																}}
																className="w-100 connect-button-ig-fb"
															>
																<i className="fa fa-instagram" /> &nbsp;Connect Instagram
															</Button>
														) : (
															<Button disabled className="w-100 connect-button-ig-fb">
																<i className="fa fa-instagram" /> &nbsp;Connect Instagram
															</Button>
														)}
													</>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-6 mb-20">
								<div className="social_box_main">
									<div className="social_box_header d-flex justify-content-between">
										<h5>Facebook Connection</h5>
										<span className="connection-status-badge-green">Connected</span>
									</div>
									<div className="row">
										<div className="col-md-12">
											<div className="dp_fields-setup mb-3">
												<div className="category_count">Connection Status</div>
											</div>
											<div className="dp_fields-setup">
												<div className="connected-text text-left mb-2">
													Connected: <span>@dl1961denim</span>
												</div>
												<Button className="w-100 connect-button-ig-fb">Disconnect Facebook</Button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<p>Note: Connecting Instagram and Facebook will allow you to access and post to your IG/FB account.</p>
						{/* <div className="infl-connection-screen">
							<div className="conn-list">
								<div className="conn-list-left">
									<div className="ins-icon">
										<i className="fa fa-instagram" aria-hidden="true"></i>
									</div>
									<div className="ins-detail">
										<span className="ins-detail-title">Instagram Connection</span>
										<span className="ins-detail-status">
											Connected: <span className="ins-username">@{account?.payload?.Data?.instagram_username}</span>
										</span>
									</div>
								</div>

								<div className="connection-status">
									{account?.payload?.Data?.instagram_username !== "" ? (
										<>
											<NavLink onClick={handleModal} className="ins-detail-title">
												<i className="fa fa-instagram" /> &nbsp;Disconnected
											</NavLink>
										</>
									) : (
										<>
											{account?.payload?.Data?.became_influencer === "approved" ? (
												<Button
													onClick={() => {
														window.location.replace(url);
													}}
													className="ins-detail-title"
												>
													<i className="fa fa-instagram" /> &nbsp;Connect Instagram
												</Button>
											) : (
												<Button disabled className="ins-detail-title">
													<i className="fa fa-instagram" /> &nbsp;Connect Instagram
												</Button>
											)}
										</>
									)}
								</div>
							</div>
							<div className="conn-list">
								<div className="conn-list-left">
									<div className="ins-icon">
										<AppstoreOutlined />
									</div>
									<div className="ins-detail">
										<span className="ins-detail-title">Manage Social Store</span>
									</div>
								</div>
								<div className="connection-status">{account?.payload?.Data?.instagram_username === "" ? <Button disabled>Manage</Button> : <NavLink to={`/influencer-socialstore/${account?.payload?.Data?.instagram_username}/${account?.payload?.Data?.instagram_token}`}>Manage</NavLink>}</div>
							</div>
							<div className="conn-list">
								<div className="conn-list-left">
									<div className="ins-icon">
										<i class="fa fa-reorder" aria-hidden="true"></i>
									</div>
									<div className="ins-detail">
										<span className="ins-detail-title">Reorder Store</span>
									</div>
								</div>
								<div className="connection-status">{account?.payload?.Data?.instagram_username === "" ? <Button disabled>Manage</Button> : <NavLink to="/reorder-socialstore">Manage</NavLink>}</div>
							</div>

							<div className="conn-list">
								<div className="conn-list-left">
									<div className="ins-icon">
										<i className="fa fa-picture-o"></i>
									</div>
									<div className="ins-detail">
										<span className="ins-detail-title">Manage Gallery</span>
									</div>
								</div>
								<div className="connection-status">{account?.payload?.Data?.instagram_username === "" ? <Button disabled>Manage</Button> : <NavLink to="/mediagallery">Manage</NavLink>}</div>
							</div>

							<div className="conn-list">
								<div className="conn-list-left">
									<div className="ins-icon">
										<i className="fa fa-list-ul" aria-hidden="true"></i>
									</div>
									<div className="ins-detail">
										<span className="ins-detail-title">Manage Category</span>
									</div>
								</div>
								<div className="connection-status">{account?.payload?.Data?.instagram_username === "" ? <Button disabled>Manage</Button> : <NavLink to="/manageCategory">Manage</NavLink>}</div>
							</div>
						</div> */}
					</div>
				</div>
			</div>

			<Modal show={disconnectModal} onHide={() => setDisconnectModal(false)} className="modal-instagram" centered>
				<Modal.Header closeButton>
					<Modal.Title>Disconnect Instagram</Modal.Title>
				</Modal.Header>
				<Modal.Body className="bg-white">
					Are you sure you want to disconnect
					<span className="strong"> @{account?.payload?.Data?.instagram_username}</span> account from Konnect.bio?
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={() => setDisconnectModal(false)}>Close</Button>
					<Button
						className="disconnect-btn btn-danger"
						onClick={() => {
							disconnect();
						}}
						disabled={disconnectLoading}
					>
						Yes
					</Button>
				</Modal.Footer>
			</Modal>

			<BecomeInfluenerModal
				setBecomeInfluencerModal={(value) => {
					setBecomeInfluencerModal(value);
				}}
				becomeInfluencerModal={becomeInfluencerModal}
			/>
		</>
	);
}
