import { NavLink } from "react-router-dom";
import "../../css/live-events.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function InfluencersReview() {
    const liveevents = {
		className: "slider variable-width",
		dots: false,
		infinite: false,
		centerMode: false,
		slidesToShow: 5.5,
		swipe: false,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1350,
				settings: {
					slidesToShow: 4.2,
					swipe: true,
				},
			},
			{
				breakpoint: 990,
				settings: {
					slidesToShow: 3.2,
					swipe: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1.5,
					swipe: true,
				},
			},
		],
	};
  return (
    
    <>
      <div className="event-container" id="influencers">
        <div className="event-header">
          <h3>Influencers Reviews</h3>
        </div>
        <Slider {...liveevents}>
          <div className="event-col-box">
            <NavLink to="/influencer-reviews" className="live-vid-link">
              <div className="vid-item">
                <img src="https://cdn.shopify.com/s/files/1/1698/6315/articles/154078066_3840355622708575_4418324325494105805_n_1024x.jpg?v=1614290282" />
                <div className="vid-item-detail">
                  <div className="vid-time">
                    <span className="vid-views">
                      <i className="fa fa-play" aria-hidden="true"></i> 59.5k
                    </span>
                  </div>
                  <div className="vid-title">
                    <div className="author-name">
                      <span className="auth-image">
                        <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                      </span>
                      <span className="auth-title">_alyssa_lorraine_</span>
                    </div>
                  </div>
                </div>
              </div>
            </NavLink>
          </div>
          <div className="event-col-box">
            <NavLink to="/influencer-reviews" className="live-vid-link">
              <div className="vid-item">
                <img src="https://images.squarespace-cdn.com/content/v1/514ecafde4b045db9417fa2f/1634566581598-8Q7DGGYPQR8ZHBSYQ1MV/JR_Marlen_5x7_Postcard.jpg" />
                <div className="vid-item-detail">
                  <div className="vid-time">
                    <span className="vid-views">
                      <i className="fa fa-play" aria-hidden="true"></i> 59.5k
                    </span>
                  </div>
                  <div className="vid-title">
                    <div className="author-name">
                      <span className="auth-image">
                        <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                      </span>
                      <span className="auth-title">_alyssa_lorraine_</span>
                    </div>
                  </div>
                </div>
              </div>
            </NavLink>
          </div>
          <div className="event-col-box">
            <NavLink to="/influencer-reviews" className="live-vid-link">
              <div className="vid-item">
                <img src="https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/ff-collection-shot-models-original-1629924514.jpg" />
                <div className="vid-item-detail">
                  <div className="vid-time">
                    <span className="vid-views">
                      <i className="fa fa-play" aria-hidden="true"></i> 59.5k
                    </span>
                  </div>
                  <div className="vid-title">
                    <div className="author-name">
                      <span className="auth-image">
                        <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                      </span>
                      <span className="auth-title">_alyssa_lorraine_</span>
                    </div>
                  </div>
                </div>
              </div>
            </NavLink>
          </div>
          <div className="event-col-box">
            <NavLink to="/influencer-reviews" className="live-vid-link">
              <div className="vid-item">
                <img src="https://media1.popsugar-assets.com/files/thumbor/HDjdstm17IyKfb4D4dp21SZiHBw/fit-in/2048xorig/filters:format_auto-!!-:strip_icc-!!-/2018/08/29/701/n/44285655/1e399895360866ee_AdidasOriginal_FW18_Falcon_B28126_Look2_FullBody_0190_03/i/Kylie-Jenner-Adidas-Falcon-Sneakers-2018.jpg" />
                <div className="vid-item-detail">
                  <div className="vid-time">
                    <span className="vid-views">
                      <i className="fa fa-play" aria-hidden="true"></i> 59.5k
                    </span>
                  </div>
                  <div className="vid-title">
                    <div className="author-name">
                      <span className="auth-image">
                        <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                      </span>
                      <span className="auth-title">_alyssa_lorraine_</span>
                    </div>
                  </div>
                </div>
              </div>
            </NavLink>
          </div>
          <div className="event-col-box">
            <NavLink to="/influencer-reviews" className="live-vid-link">
              <div className="vid-item">
                <img src="https://cdn.shopify.com/s/files/1/2397/3099/products/13246_Hepburn_Keys_042_650x.jpg?v=1643730359" />
                <div className="vid-item-detail">
                  <div className="vid-time">
                    <span className="vid-views">
                      <i className="fa fa-play" aria-hidden="true"></i> 59.5k
                    </span>
                  </div>
                  <div className="vid-title">
                    <div className="author-name">
                      <span className="auth-image">
                        <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                      </span>
                      <span className="auth-title">_alyssa_lorraine_</span>
                    </div>
                  </div>
                </div>
              </div>
            </NavLink>
          </div>
          <div className="event-col-box">
            <NavLink to="/influencer-reviews" className="live-vid-link">
              <div className="vid-item">
                <img src="https://thevou.com/wp-content/uploads/2021/10/PlusSizeClothing.thevou.com_.warpweftworld.01.jpg" />
                <div className="vid-item-detail">
                  <div className="vid-time">
                    <span className="vid-views">
                      <i className="fa fa-play" aria-hidden="true"></i> 59.5k
                    </span>
                  </div>
                  <div className="vid-title">
                    <div className="author-name">
                      <span className="auth-image">
                        <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                      </span>
                      <span className="auth-title">_alyssa_lorraine_</span>
                    </div>
                  </div>
                </div>
              </div>
            </NavLink>
          </div>
        </Slider>
      </div>
    </>
  );
};
