import { NavLink } from "react-router-dom";
import "../../css/live-events.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function TrendingShows() {
  const liveevents = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 5.5,
    swipe: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 4.2,
          swipe: true,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 3.2,
          swipe: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
          swipe: true,
        },
      },
    ],
  };
  return (
    <>
      <div className="event-container">
        <div className="event-header">
          <h3>Trending Shows</h3>
        </div>
        <Slider {...liveevents}>
          <div className="event-col-box">
            <a href="#" className="live-vid-link">
              <div className="vid-item">
                <img src="https://www.instyle.com/thmb/o9zDkWS9Xh01CzjC32Q-3ElHK0Q=/400x0/filters:no_upscale():max_bytes(150000):strip_icc()/emrata-dl1961-jeans-campaign-new-embed-02-c2be4bc733c84b4784f02be76f81f881.jpg" />
                <div className="vid-item-detail">
                  <div className="vid-time">
                    <span>17 days ago</span>
                  </div>
                  <div className="vid-title">
                    <span className="v-title">Emily Ratajkowski's Tricks to Wearing Denim Like No Other</span>
                    <div className="author-name">
                      <span className="auth-image">
                        <img src="https://static.konnect.bio/banner/9a87d50f-2032-44b7-a982-942704564b84.jpg" />
                      </span>
                      <span className="auth-title">DL1961 Premium Denim</span>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="event-col-box">
            <a href="#" className="live-vid-link">
              <div className="vid-item">
                <img src="https://i.pinimg.com/originals/4d/21/8e/4d218ef128ea24ad7683fcb2b0c96854.jpg" />
                <div className="vid-item-detail">
                  <div className="vid-time">
                    <span>22 days ago</span>
                  </div>
                  <div className="vid-title">
                    <span className="v-title">Warp + Weft Sustainable and ethical jeans</span>
                    <div className="author-name">
                      <span className="auth-image">
                        <img src="https://static.konnect.bio/banner/4f8a2fab-320b-4429-a4b0-afabb291b06d.jpg" />
                      </span>
                      <span className="auth-title">Warp + Weft</span>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="event-col-box">
            <a href="#" className="live-vid-link">
              <div className="vid-item">
                <img src="https://imgmedia.lbb.in/media/2020/08/20/BE9FF1DE-38E8-4CAA-9DD1-B75D8E883806.jpg" />
                <div className="vid-item-detail">
                  <div className="vid-time">
                    <span>1 month ago</span>
                  </div>
                  <div className="vid-title">
                    <span className="v-title">Get That Gorgeous Eye Makeup With These Highly Pigmented Kohls!</span>
                    <div className="author-name">
                      <span className="auth-image">
                        <img src="https://static.konnect.bio/banner/7d9d4090-26a7-4acf-9b3d-a09cac674f01.jpg" />
                      </span>
                      <span className="auth-title">Kohl's</span>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="event-col-box">
            <a href="#" className="live-vid-link">
              <div className="vid-item">
                <img src="http://cdn.shopify.com/s/files/1/0413/6779/7922/products/JR_Vienna_New_7_5844f6be-ff48-486a-b3d4-0d16c652dc37_1800x1800_1_1200x1200.jpg?v=1642101968" />
                <div className="vid-item-detail">
                  <div className="vid-time">
                    <span>3 months ago</span>
                  </div>
                  <div className="vid-title">
                    <span className="v-title">Judith Ripka Vienna Double Stirrup Necklace</span>
                    <div className="author-name">
                      <span className="auth-image">
                        <img src="https://static.konnect.bio/banner/07541cb3-e242-4f29-a4c1-2d7a5efb166a.jpg" />
                      </span>
                      <span className="auth-title">Judith Ripka</span>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="event-col-box">
            <a href="#" className="live-vid-link">
              <div className="vid-item">
                <img src="https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/ff-collection-shot-models-original-1629924514.jpg" />
                <div className="vid-item-detail">
                  <div className="vid-time">
                    <span>3 months ago</span>
                  </div>
                  <div className="vid-title">
                    <span className="v-title">Fashion Fair Makeup Relaunching</span>
                    <div className="author-name">
                      <span className="auth-image">
                        <img src="https://static.konnect.bio/banner/d73b7f9b-2e5b-4c94-9c87-6a1899723b3e.jpg" />
                      </span>
                      <span className="auth-title">Sephora</span>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="event-col-box">
            <a href="#" className="live-vid-link">
              <div className="vid-item">
                <img src="https://media1.popsugar-assets.com/files/thumbor/HDjdstm17IyKfb4D4dp21SZiHBw/fit-in/2048xorig/filters:format_auto-!!-:strip_icc-!!-/2018/08/29/701/n/44285655/1e399895360866ee_AdidasOriginal_FW18_Falcon_B28126_Look2_FullBody_0190_03/i/Kylie-Jenner-Adidas-Falcon-Sneakers-2018.jpg" />
                <div className="vid-item-detail">
                  <div className="vid-time">
                    <span>5 months ago</span>
                  </div>
                  <div className="vid-title">
                    <span className="v-title">Amazing deal UP TO 72% OFF</span>
                    <div className="author-name">
                      <span className="auth-image">
                        <img src="https://static.konnect.bio/banner/98a0b149-cf5f-4f0f-afbf-461bed6c25b1.jpg" />
                      </span>
                      <span className="auth-title">Adidas</span>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </Slider>
      </div>
    </>
  );
}
