import React, { useState, useEffect } from "react";
import { Button, Form } from "antd";
import { Spin, Select, Input, Checkbox } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { createShippingAddress, getAllShippingAddress, updateDefaultShippingAddress, updatedShippingAddress, deleteShipAddress } from "../../../redux/actions/shipping.action";
import { getCountry } from "../../../redux/actions/country.action";
import { getState } from "../../../redux/actions/state.action";
import { getCity } from "../../../redux/actions/city.action";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import "../../../css/account-setup.scss";
import FloatLabel from "../../../components/floatLabel";
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
export default function ShippingAddress({ cart, shippingAddressId }) {
	const userInfo = JSON.parse(localStorage.getItem("userInfo"));
	const { addShippingAddress, allShippingAddress, setShippingAddress, deleteShippingAddress, upgradeShippingAddress } = useSelector((state) => state);
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const [addAddress, setAddAddress] = useState(false);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [address, setAddress] = useState("");
	const [apartment, setApartment] = useState("");
	const [country, setCountry] = useState("");
	const [state, setState] = useState("");
	const [city, setCity] = useState("");
	const [company, setCompany] = useState("");
	const [zipCode, setZipCode] = useState("");
	const [phone, setPhone] = useState("");
	const [countryList, setCountryList] = useState([]);
	const [stateList, setStateList] = useState([]);
	const [cityList, setCityList] = useState([]);
	const [editSingleId, setEditSingleId] = useState("");

	useEffect(() => {
		if (shippingAddressId === undefined) {
			dispatch(getAllShippingAddress(userInfo?.token));
		}
		getCountryFunc();
	}, []);

	useEffect(() => {
		if (cart) {
			setAddAddress(true);
		}
	}, [cart]);

	const onReset = () => {
		setFirstName();
		setLastName();
		setAddress();
		setApartment();
		setCountry();
		setCity();
		setPhone();
		setZipCode();
		setCompany();
		setEditSingleId();
		setState();
	};

	useEffect(() => {
		if (shippingAddressId) {
			setEditSingleId(shippingAddressId);
			setAddAddress(true);
		}
	}, [shippingAddressId]);

	useEffect(() => {
		if (editSingleId) {
			let singleShippingAddress = allShippingAddress?.payload?.message.filter((item) => item?._id === editSingleId)[0];

			getStateFunc(singleShippingAddress?.country).then(() => {
				form.setFieldsValue({
					state: singleShippingAddress?.province,
				});
				setState(singleShippingAddress?.province);
			});
			getCityFunc(singleShippingAddress?.country, singleShippingAddress?.province).then(() => {
				form.setFieldsValue({
					city: singleShippingAddress.city,
				});
				setCity(singleShippingAddress.city);
			});
			form.setFieldsValue({
				firstName: singleShippingAddress.firstName,
				lastName: singleShippingAddress.lastName,
				address: singleShippingAddress.address1,
				apartment: singleShippingAddress.address2,
				city: singleShippingAddress.city,
				country: singleShippingAddress.country,
				zip: singleShippingAddress.zip,
				company: singleShippingAddress.company,
				phone: singleShippingAddress.phone,
			});
			setFirstName(singleShippingAddress?.firstName);
			setLastName(singleShippingAddress?.lastName);
			setAddress(singleShippingAddress?.address1);
			setApartment(singleShippingAddress?.address2);
			setCountry(singleShippingAddress?.country);

			setCity(singleShippingAddress?.city);
			setPhone(singleShippingAddress?.phone);
			setZipCode(singleShippingAddress?.zip);
			setCompany(singleShippingAddress?.company);
		}
	}, [editSingleId]);

	const onFinish = async (values) => {
		if (editSingleId) {
			dispatch(updatedShippingAddress({ ...values, address_id: editSingleId }, userInfo?.token)).then(() => {
				dispatch(getAllShippingAddress(userInfo?.token));
				form.resetFields();
				setAddAddress(false);
				window.scrollTo(0, 0);
				Swal.fire({
					icon: "success",
					title: "Shipping address updated successfully!",
				});
			});
		} else {
			if (userInfo.token) {
				dispatch(createShippingAddress(values, userInfo?.token)).then(() => {
					dispatch(getAllShippingAddress(userInfo?.token));
					form.resetFields();
					setAddAddress(false);
					window.scrollTo(0, 0);
					Swal.fire({
						icon: "success",
						title: "Shipping address added successfully!",
					});
				});
			}
		}
	};
	const onFinishFailed = (errorInfo) => console.log("Failed:", errorInfo);
	const getCountryFunc = () => {
		if (country.length === 0) {
			dispatch(getCountry()).then((res) => {
				const selectCountries = [];
				res.payload.message.map(({ name, code1 }) => {
					return selectCountries.push({ value: code1, label: name });
				});
				setCountryList(selectCountries);
			});
		}
	};

	const getStateFunc = (country) => {
		return dispatch(getState(country)).then((res) => {
			const selectStates = [];
			res.payload.message.map(({ name, isoCode }) => {
				return selectStates.push({ value: isoCode, label: name });
			});
			setStateList(selectStates);
			setCityList([]);
		});
	};

	const getCityFunc = (country, state) => {
		return dispatch(getCity(country, state)).then((res) => {
			const selectStates = [];
			res.payload.message.map(({ name }) => {
				return selectStates.push({ value: name, label: name });
			});
			setCityList(selectStates);
		});
	};

	const changeCountry = (value) => {
		setCountry(value);
		getStateFunc(value);
		form.resetFields(["state"]);
		form.resetFields(["city"]);
	};
	const changeState = (value) => {
		setState(value);
		getCityFunc(country, value);
		form.resetFields(["city"]);
	};

	const changeCity = (value) => setCity(value);

	useEffect(() => {
		document.body.classList.add("bioshop-body", "shopper-bioshop-body");
	  }, []);
	
	  // This is componentWillUnmount
	  useEffect(() => {
		return () => {
		//  componentWillUnmount.current = true;
		  document.body.classList.remove("bioshop-body", "shopper-bioshop-body");
		};
	  }, []);

	return (
		<>
			<div className="account-left-content">
				<div className="plus-btn">
					{!cart && (
						<Button
							onClick={() => {
								setAddAddress(true);
								form.resetFields();
								onReset(true);
							}}
							className="plus-address"
						>
							<i className="fa fa-plus" aria-hidden="true"></i>&nbsp;Add Address
						</Button>
					)}
				</div>

				<div className="account-detail col-12 col-xl-6 col-lg-8 col-md-12">
					<div className="card-information-store">
						{allShippingAddress?.loading || setShippingAddress?.loading ? <Spin className="d-flex justify-content-center mt-4" size="large" /> : ""}
						{!addAddress && (
							<div className={`address-list`}>
								{!allShippingAddress?.loading &&
									allShippingAddress?.payload?.message?.map((item, key) => {
										return item.is_default ? (
											<div key={key} className="address-box dft-box">
												<div className="ship-address-iner">
													<h4>Default</h4>
													<span>{item?.address1}</span>
													<span>{item?.address2}</span>
													<span>
														{item?.city}, {item?.zip}
													</span>
													<span>{item.province}</span>
												</div>
												<div className="ship-btns">
													<Button
														onClick={() => {
															setAddAddress(true);
															setEditSingleId(item?._id);
														}}
													>
														Edit
													</Button>
												</div>
											</div>
										) : (
											<div key={key} className="address-box">
												<div className="ship-address-iner">
													<span>{item?.address1}</span>
													<span>{item?.address2}</span>
													<span>
														{item?.city}, {item?.zip}
													</span>
													<span>{item.province}</span>
												</div>
												<div className="ship-btns">
													<Button
														onClick={() => {
															setAddAddress(true);
															setEditSingleId(item?._id);
														}}
													>
														Edit
													</Button>
													{deleteShippingAddress.loading ? (
														<Button>
															{" "}
															<Spin indicator={antIcon} />
														</Button>
													) : (
														<Button
															onClick={() => {
																dispatch(deleteShipAddress(item?._id, userInfo.token)).then(() => {
																	dispatch(getAllShippingAddress(userInfo?.token));
																	window.scrollTo(0, 0);
																	Swal.fire({
																		icon: "success",
																		title: "Address deleted successfully!",
																	});
																});
															}}
														>
															Delete
														</Button>
													)}
												</div>
												<Checkbox
													onChange={() => {
														dispatch(updateDefaultShippingAddress(item._id, userInfo.token)).then(() => {
															dispatch(getAllShippingAddress(userInfo?.token));
															window.scrollTo(0, 0);
															Swal.fire({
																icon: "success",
																title: "Default shipping address updated successfully!",
															});
														});
													}}
												>
													Set as default address
												</Checkbox>
											</div>
										);
									})}
							</div>
						)}
						<Form onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off" form={form} size="large" name="user_login" className={`login-form ${addAddress ? "d-block" : "d-none"}`} layout="vertical">
							<div className="billing-address-store">
								<div className="cd-detail-box cd-detail-box-gap">
									<div className="cd-2col">
										<FloatLabel label="First Name" name="firstName" value={firstName}>
											<Form.Item
												name="firstName"
												rules={[
													{
														required: true,
														message: "Please insert your first name",
													},
												]}
											>
												<Input value={firstName} size="large" onChange={(e) => setFirstName(e.target.value)} />
											</Form.Item>
										</FloatLabel>
									</div>
									<div className="cd-2col">
										<FloatLabel label="Last Name" name="lastName" value={lastName}>
											<Form.Item
												name="lastName"
												rules={[
													{
														required: true,
														message: "Please insert your last name",
													},
												]}
											>
												<Input value={lastName} size="large" onChange={(e) => setLastName(e.target.value)} />
											</Form.Item>
										</FloatLabel>
									</div>
								</div>
								<div className="cd-detail-box">
									<FloatLabel label="Address" name="address" value={address}>
										<Form.Item
											name="address"
											rules={[
												{
													required: true,
													message: "Please insert address",
												},
											]}
										>
											<Input defaultValue={address} value={address} size="large" onChange={(e) => setAddress(e.target.value)} />
										</Form.Item>
									</FloatLabel>
								</div>
								<div className="cd-detail-box">
									<FloatLabel label="Apartment, suite, etc. (optional)" name="apartmet" value={apartment}>
										<Form.Item name="apartment">
											<Input value={apartment} size="large" onChange={(e) => setApartment(e.target.value)} />
										</Form.Item>
									</FloatLabel>
								</div>
								<div className="cd-detail-box cd-detail-box-gap">
									<div className="cd-2col">
										<FloatLabel label="Select Country" name="country" value={country}>
											<Form.Item
												name="country"
												rules={[
													{
														required: true,
														message: "Please insert country",
													},
												]}
											>
												<Select showSearch placeholder="Select Country" size="large" optionFilterProp="children" onChange={changeCountry} filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
													{countryList?.map((item) => (
														<Option value={item.value}>{item.label}</Option>
													))}
												</Select>
											</Form.Item>
										</FloatLabel>
									</div>
									<div className="cd-2col">
										<FloatLabel label="Select State" name="state" value={state}>
											<Form.Item
												name="state"
												rules={[
													{
														required: true,
														message: "Please insert state",
													},
												]}
											>
												<Select showSearch placeholder="Select State" optionFilterProp="children" size="large" onChange={changeState} filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
													{stateList?.map((item) => (
														<Option value={item?.value}>{item?.label}</Option>
													))}
												</Select>
											</Form.Item>
										</FloatLabel>
									</div>
								</div>
								<div className="cd-detail-box cd-detail-box-gap">
									<div className="cd-2col">
										<FloatLabel label="Select City" name="city" value={city}>
											<Form.Item
												name="city"
												rules={[
													{
														required: true,
														message: "Please insert ciity",
													},
												]}
											>
												<Select onChange={changeCity} showSearch placeholder="Select City" size="large" optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}>
													{cityList?.map((item) => (
														<Option value={item.value}>{item.label}</Option>
													))}
												</Select>
											</Form.Item>
										</FloatLabel>
									</div>
									<div className="cd-2col">
										<FloatLabel label="Zip Code" name="zipcode" value={zipCode} >
											<Form.Item
												
												name="zip"
												rules={[
													{
														required: true,
														message: "Please insert zip code",
													},
													{
														message: "Please enter valid zip code",
														pattern: new RegExp(/^[0-9]+$/),
													},
												]}
											>
												<Input value={zipCode} type='number' size="large" onChange={(e) => setZipCode(e.target.value)} />
											</Form.Item>
										</FloatLabel>
									</div>
								</div>
								<div className="cd-detail-box cd-detail-box-gap">
									<div className="cd-2col">
										<FloatLabel label="Company (optional)" name="state" value={company}>
											<Form.Item name="company">
												<Input value={company} size="large" onChange={(e) => setCompany(e.target.value)} />
											</Form.Item>
										</FloatLabel>
									</div>
									<div className="cd-2col">
										<FloatLabel label="Phone" name="phone" value={phone}>
											<Form.Item
												name="phone"
												rules={[
													{
														required: true,
														message: "Please insert phone number",
													},
													{
														message: "Please enter valid phone number",
														pattern: new RegExp(/^[0-9]+$/),
													},
												]}
											>
												<Input value={phone} type='number' size="large" onChange={(e) => setPhone(e.target.value)} />
											</Form.Item>
										</FloatLabel>
									</div>
								</div>
								<div className="cd-detail-box cd-detail-box-gap">
									{addShippingAddress?.loading || upgradeShippingAddress.loading ? (
										<Button>
											{" "}
											<Spin indicator={antIcon} />
										</Button>
									) : (
										<Button htmlType="submit">Save</Button>
									)}
									<Button
										onClick={() => {
											setAddAddress(false);
										}}
										htmlType="button"
									>
										Cancel
									</Button>
								</div>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</>
	);
}
