import React, { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { openPasswordModal } from "../../redux/actions/changePassword.action";
import "../../css/account-setup.scss";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function PasswordSetting() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(openPasswordModal(false));
  }, []);

  return (
    <>
      <h3 className="pl-0">Change Password</h3>
      <div className="pr-op-block">
        <div className="ps-title">Password</div>
        <div className="password-box">
          <span className="hide-pass">************</span>
          <div className="ps-btns">
          <span
            onClick={() => {
              dispatch(openPasswordModal(true));
            }}
            className="change-password"
          >
            Change Password
          </span>
          <Link  to="/dashboard" className="change-password">
            Exit
          </Link>
          </div>
          


        </div>
      </div>
    </>
  );
}
