import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Statistic } from "antd";
import "../../css/live-events.scss";
import { Button } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import closeicon from "../../images/brand-logos/close-icon.svg";
import { getUpComingEvent } from "../../redux/actions/liveShopping.action";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import { createBrowserHistory } from "history";
import queryString from "query-string";
import BrandLiveEvent from "../../components/brandLiveEvent/brandLiveEvent";
import avatarIcon from "../../images/avatar.png";

export const history = createBrowserHistory({
  forceRefresh: false,
});

export default () => {
  const { Countdown } = Statistic;
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const id = params.id;
  const username = params.username;
  const onFinish = () => {
    navigate("/live-event");
  };
  const queryParams = queryString.parse(window.location.search);
  const { upComingEvent, account } = useSelector((state) => {
    return state;
  });

  let userInfo = localStorage.getItem("userInfo");

  if (userInfo !== "undefined") {
    userInfo = JSON.parse(localStorage.getItem("userInfo"));
  } else {
    localStorage.removeItem("userInfo");
    window.location.reload();
  }

  useEffect(() => {
    dispatch(getUpComingEvent(id)).then((res) => {
      if (res?.payload?.message?.length === 0) {
        navigate(`/live-event/${id}/${username}`);
        window.location.reload();
      }
    });
  }, [id]);

  const liveevents = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 5.5,
    swipe: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 4.2,
          swipe: true,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 3.2,
          swipe: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
          swipe: true,
        },
      },
    ],
  };

  const ref = React.useRef();

  React.useEffect(() => {
    if (upComingEvent?.payload?.message[0]?.start_date) {
      const oldFormatter = ref?.current?.formatCountdown;
      ref.current.formatCountdown = (...params) => {
        const result = oldFormatter(...params);
        let [day, hour, minute, second] = result.split(":");
        return (
          <>
            <div className="dhms">
              {day} <span>DAYS</span>
            </div>
            <div className="dhms">
              {hour} <span>HOURS</span>
            </div>
            <div className="dhms">
              {minute} <span>MINS</span>
            </div>
            <div className="dhms">
              {second} <span>SECS</span>
            </div>
          </>
        );
      };
    }
  }, [upComingEvent?.payload?.message[0]?.start_date]);

  if (upComingEvent?.payload?.message) {
    return (
      <>
        <div className="live-event-main">
          {upComingEvent?.payload && (
            <div className="live-video-container">
              <NavLink to="/" className="close-video">
                <img src={closeicon} className="filter-gray" />
              </NavLink>
              <div className="video-boxes-main upcoming-box-main">
                <div className={`upcoming-poster ${upComingEvent?.payload?.message[0]?.default_banner ? "default-image" : ""}`}>
                  <img src={upComingEvent?.payload?.message[0]?.banner} />
                </div>
                <div className="upcoming-timer">
                  <div className="countdown">
                    <span className="timer-title">This live event will start in </span>
                    <Countdown ref={ref} format={"DD:HH:mm:ss"} value={moment(upComingEvent?.payload?.message[0]?.start_date).utc()} onFinish={onFinish} />
                    <div className="rsvp-btns">
                      <NavLink to="/">RSVP</NavLink>
                      <NavLink to="/">Add to my store</NavLink>
                      <NavLink to="/">Share</NavLink>
                      <NavLink to="/">Cancel</NavLink>
                    </div>
                    <div className="fee-row">
                      {upComingEvent?.payload?.message[0]?.discount !== "0%" && upComingEvent?.payload?.message[0]?.discount ? <span className="off-percentage">{upComingEvent?.payload?.message[0]?.discount} Off</span> : ""}

                      {/* 										
											
											{upComingEvent?.payload?.message[0]?.referral_percent && <span className="ref-fee">{upComingEvent?.payload?.message[0]?.referral_percent}% Referral Fee</span>}
											{upComingEvent?.payload?.message[0].influencer_percent && <span className="inf-fee">{upComingEvent?.payload?.message[0].influencer_percent}% Influencer Fee</span>} */}

                      {userInfo?.account_type !== "influencer" ? upComingEvent?.payload?.message[0]?.referral_percent !== "0" && upComingEvent?.payload?.message[0]?.referral_percent !== "undefined" && upComingEvent?.payload?.message[0]?.referral_percent ? <span className="ref-fee">{upComingEvent?.payload?.message[0]?.referral_percent}% Referral Fee</span> : "" : ""}
                      {userInfo && account?.payload?.Data?.account_type !== "customer" ? upComingEvent?.payload?.message[0].influencer_percent !== "0" && upComingEvent?.payload?.message[0]?.influencer_percent !== "undefined" && upComingEvent?.payload?.message[0].influencer_percent ? <span className="inf-fee">{upComingEvent?.payload?.message[0].influencer_percent}% Influencer Fee</span> : "" : ""}
                      {upComingEvent?.payload?.message[0].promo && upComingEvent?.payload?.message[0].promo != "KB0" ? <span className="inf-fee">Promo {upComingEvent?.payload?.message[0].promo}</span> : ""}
                    </div>

                    <span className="notify-button">
                      <Button variant="secondary">Notify me</Button>
                    </span>
                    <span className="broadcaster-name">{upComingEvent?.payload?.message[0]?.title}</span>
                    <div className="tim-author-name">
                      <span className="tim-auth-image">{upComingEvent?.payload?.message[0]?.brand_profile ? <img src={upComingEvent?.payload?.message[0]?.brand_profile} /> : <img className="img-fluid" src={avatarIcon} />}</span>
                      <span className="tim-auth-title">{upComingEvent?.payload?.message[0]?.brand_name}</span>
                    </div>

                    {/* 
                    
                    <div className="tim-author-name">
                      <span className="tim-auth-image">
                        <img src={upComingEvent?.payload?.message[0]?.brand_profile } />
                      </span>
                      <span className="tim-auth-title">{upComingEvent?.payload?.message[0]?.brand_name}</span>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="midd-width-ifti">
            <div className="live-shows">
              <BrandLiveEvent />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="live-event-main">
        <div className="live-video-container">
          <NavLink to="/live-shopping" className="close-video">
            <img src={closeicon} className="filter-gray" />
          </NavLink>
          <div className="video-boxes-main upcoming-box-main">
            <div className="upcoming-poster"></div>
            <div className="upcoming-timer">
              <div className="countdown">
                <span className="timer-title">This live event will start in</span>
                <span className="notify-button">
                  <Button variant="secondary">Notify me</Button>
                </span>
                <span className="broadcaster-name">Candice Swanepoel Fronts DL1961</span>
                <div className="tim-author-name">
                  <span className="tim-auth-image">
                    <img src="https://static.konnect.bio/banner/9a87d50f-2032-44b7-a982-942704564b84.jpg" />
                  </span>
                  <span className="tim-auth-title">DL1961 Premium Denim</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
