import Layout from "./component/layout";
import SocialStore from "./component/socialStore";

export default () => {
  return (
    <>
      <Layout title="Manage Social Store">
        <SocialStore socialStore={true} />
      </Layout>
    </>
  );
};
