import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import "../../css/influencer-reviews.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import closeicon from "../../images/brand-logos/close-icon.svg";

export default () => {
  const [showProducts, setShowProducts] = useState(false);

  useEffect(() => {
    document.body.classList.add("customer-reviews-body");
    return () => {
      document.body.classList.remove("customer-reviews-body");
    };
  }, []);

  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 1,
    vertical: true,
    verticalSwiping: true,
    slidesToScroll: 1,
  };
  const otherreviews = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 5.5,
    swipe: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1650,
        settings: {
          slidesToShow: 4.7,
          swipe: true,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3.7,
          swipe: true,
        },
      },
    ],
  };

  return (
    <>
      <div className="infl-rev-main-container">
        <div className="rev-container">
          <div className="rev-video-container">
            <NavLink to="/live-events/dl1961denim#influencers" className="close-video">
              <img src={closeicon} className="filter-gray" />
            </NavLink>
            <div className="rev-video-box-main">
              <Slider {...settings}>
                <div className="rev-vid-panel">
                  <div className="rev-vid-panel-inr">
                    <div className="rev-vid-dm1">
                      <video
                        controlsList="nodownload"
                        preload="metadata"
                        webkit-playsInline 
                        playsInline
                        controls
                        muted
                        autoPlay
                        loop
                        width="100%"
                        height="100%"
                      >
                        <source
                          src="https://static.konnect.bio/900000005/posts/5aa069eb-2133-4135-8d97-7c3791760657.mp4#t=0.001"
                          type="video/mp4"
                        ></source>
                      </video>
                      <div className="influ-detail">
                        <div className="inf-title">_alyssa_lorraine_</div>
                        <span className="infl-image">
                          <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                        </span>
                      </div>
                      <div className="influ-product-popup">
                        <Button
                          onClick={() => {
                            setShowProducts(true);
                          }}
                          variant="primary"
                        >
                          Products
                        </Button>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="infl-image-outer">
                    <div className="infl-out-img">
                      <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                    </div>
                  </div>
                </div>
                 <div className="rev-vid-panel">
                  <div className="rev-vid-panel-inr">
                    <div className="rev-vid-dm1">
                      <video
                        controlsList="nodownload"
                        preload="metadata"
                        webkit-playsInline 
                        playsInline
                        controls
                        muted
                        autoPlay
                        loop
                        width="100%"
                        height="100%"
                      >
                        <source
                          src="https://static.konnect.bio/900000005/posts/a74c0449-0062-4c43-9f2a-d9f82811da47.mp4#t=0.001"
                          type="video/mp4"
                        ></source>
                      </video>
                      <div className="influ-detail">
                        <div className="inf-title">_alyssa_lorraine_</div>
                        <span className="infl-image">
                          <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                        </span>
                      </div>
                      <div className="influ-product-popup">
                        <Button
                          onClick={() => {
                            setShowProducts(true);
                          }}
                          variant="primary"
                        >
                          Products
                        </Button>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="infl-image-outer">
                    <div className="infl-out-img">
                      <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                    </div>
                  </div>
                </div>
                <div className="rev-vid-panel">
                  <div className="rev-vid-panel-inr">
                    <div className="rev-vid-dm1">
                      <video
                        controlsList="nodownload"
                        preload="metadata"
                        webkit-playsInline 
                        playsInline
                        controls
                        muted
                        autoPlay
                        loop
                        width="100%"
                        height="100%"
                      >
                        <source
                          src="https://static.konnect.bio/900000005/posts/5fa51296-0202-4e14-8882-d9ad326d7491.mp4#t=0.001"
                          type="video/mp4"
                        ></source>
                      </video>
                      <div className="influ-detail">
                        <div className="inf-title">_alyssa_lorraine_</div>
                        <span className="infl-image">
                          <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                        </span>
                      </div>
                      <div className="influ-product-popup">
                        <Button
                          onClick={() => {
                            setShowProducts(true);
                          }}
                          variant="primary"
                        >
                          Products
                        </Button>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="infl-image-outer">
                    <div className="infl-out-img">
                      <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                    </div>
                  </div>
                </div>
                <div className="rev-vid-panel">
                  <div className="rev-vid-panel-inr">
                    <div className="rev-vid-dm1">
                      <video
                        controlsList="nodownload"
                        preload="metadata"
                        webkit-playsInline 
                        playsInline
                        controls
                        muted
                        autoPlay
                        loop
                        width="100%"
                        height="100%"
                      >
                        <source
                          src="https://static.konnect.bio/900000005/posts/e5150fe9-84d6-4115-82e1-ea10fd5a6c94.mp4#t=0.001"
                          type="video/mp4"
                        ></source>
                      </video>
                      <div className="influ-detail">
                        <div className="inf-title">_alyssa_lorraine_</div>
                        <span className="infl-image">
                          <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                        </span>
                      </div>
                      <div className="influ-product-popup">
                        <Button
                          onClick={() => {
                            setShowProducts(true);
                          }}
                          variant="primary"
                        >
                          Products
                        </Button>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="infl-image-outer">
                    <div className="infl-out-img">
                      <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                    </div>
                  </div>
                </div>
                <div className="rev-vid-panel">
                  <div className="rev-vid-panel-inr">
                    <div className="rev-vid-dm1">
                      <video
                        controlsList="nodownload"
                        preload="metadata"
                        webkit-playsInline 
                        playsInline
                        controls
                        muted
                        autoPlay
                        loop
                        width="100%"
                        height="100%"
                      >
                        <source
                          src="https://static.konnect.bio/900000005/posts/09648efd-eb0c-4314-b353-a55724e4c619.mp4#t=0.001"
                          type="video/mp4"
                        ></source>
                      </video>
                      <div className="influ-detail">
                        <div className="inf-title">_alyssa_lorraine_</div>
                        <span className="infl-image">
                          <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                        </span>
                      </div>
                      <div className="influ-product-popup">
                        <Button
                          onClick={() => {
                            setShowProducts(true);
                          }}
                          variant="primary"
                        >
                          Products
                        </Button>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="infl-image-outer">
                    <div className="infl-out-img">
                      <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                    </div>
                  </div>
                </div>
                <div className="rev-vid-panel">
                  <div className="rev-vid-panel-inr">
                    <div className="rev-vid-dm1">
                      <video
                        controlsList="nodownload"
                        preload="metadata"
                        webkit-playsInline 
                        playsInline
                        controls
                        muted
                        autoPlay
                        loop
                        width="100%"
                        height="100%"
                      >
                        <source
                          src="https://static.konnect.bio/900000005/posts/fe617709-8700-4661-b167-9e62b3242937.mp4#t=0.001"
                          type="video/mp4"
                        ></source>
                      </video>
                      <div className="influ-detail">
                        <div className="inf-title">_alyssa_lorraine_</div>
                        <span className="infl-image">
                          <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                        </span>
                      </div>
                      <div className="influ-product-popup">
                        <Button
                          onClick={() => {
                            setShowProducts(true);
                          }}
                          variant="primary"
                        >
                          Products
                        </Button>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="infl-image-outer">
                    <div className="infl-out-img">
                      <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
          <div className="rev-content-container">
            <div className="review-container">
              <div className="event-container event-prod-container">
                <h3>Products in this video</h3>
                <Slider {...otherreviews}>
                  <div className="event-product-box">
                    <NavLink to="/influencer-reviews" className="revi-products">
                      <div className="rev-pro-image">
                        <img src="https://cdn.shopify.com/s/files/1/2397/3099/products/20220122_DL1961_13245_EMILIE31_DARKOASIS_006.jpg?v=1643729652" />
                        <span className="rev-buy-now">
                          <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                        </span>
                      </div>
                      <div className="rev-pro-detail">
                        <span className="rev-pro-title">
                          Rachel Flare Ultra High Rise Instasculpt 35" | White
                          Raw
                        </span>
                        <span className="rev-pro-price">$33.99</span>
                      </div>
                    </NavLink>
                  </div>
                  <div className="event-product-box">
                    <NavLink to="/influencer-reviews" className="revi-products">
                      <div className="rev-pro-image">
                        <img src="https://cdn.shopify.com/s/files/1/2397/3099/products/20220122_DL1961_13245_EMILIE31_DARKOASIS_006.jpg?v=1643729652" />
                        <span className="rev-buy-now">
                          <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                        </span>
                      </div>
                      <div className="rev-pro-detail">
                        <span className="rev-pro-title">
                          Rachel Flare Ultra High Rise Instasculpt 35" | White
                          Raw
                        </span>
                        <span className="rev-pro-price">$33.99</span>
                      </div>
                    </NavLink>
                  </div>
                  <div className="event-product-box">
                    <NavLink to="/influencer-reviews" className="revi-products">
                      <div className="rev-pro-image">
                        <img src="https://cdn.shopify.com/s/files/1/2397/3099/products/20220122_DL1961_13245_EMILIE31_DARKOASIS_006.jpg?v=1643729652" />
                        <span className="rev-buy-now">
                          <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                        </span>
                      </div>
                      <div className="rev-pro-detail">
                        <span className="rev-pro-title">
                          Rachel Flare Ultra High Rise Instasculpt 35" | White
                          Raw
                        </span>
                        <span className="rev-pro-price">$33.99</span>
                      </div>
                    </NavLink>
                  </div>
                  <div className="event-product-box">
                    <NavLink to="/influencer-reviews" className="revi-products">
                      <div className="rev-pro-image">
                        <img src="https://cdn.shopify.com/s/files/1/2397/3099/products/20220122_DL1961_13245_EMILIE31_DARKOASIS_006.jpg?v=1643729652" />
                        <span className="rev-buy-now">
                          <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                        </span>
                      </div>
                      <div className="rev-pro-detail">
                        <span className="rev-pro-title">
                          Rachel Flare Ultra High Rise Instasculpt 35" | White
                          Raw
                        </span>
                        <span className="rev-pro-price">$33.99</span>
                      </div>
                    </NavLink>
                  </div>
                  <div className="event-product-box">
                    <NavLink to="/influencer-reviews" className="revi-products">
                      <div className="rev-pro-image">
                        <img src="https://cdn.shopify.com/s/files/1/2397/3099/products/20220122_DL1961_13245_EMILIE31_DARKOASIS_006.jpg?v=1643729652" />
                        <span className="rev-buy-now">
                          <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                        </span>
                      </div>
                      <div className="rev-pro-detail">
                        <span className="rev-pro-title">
                          Rachel Flare Ultra High Rise Instasculpt 35" | White
                          Raw
                        </span>
                        <span className="rev-pro-price">$33.99</span>
                      </div>
                    </NavLink>
                  </div>
                  <div className="event-product-box">
                    <NavLink to="/influencer-reviews" className="revi-products">
                      <div className="rev-pro-image">
                        <img src="https://cdn.shopify.com/s/files/1/2397/3099/products/20220122_DL1961_13245_EMILIE31_DARKOASIS_006.jpg?v=1643729652" />
                        <span className="rev-buy-now">
                          <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                        </span>
                      </div>
                      <div className="rev-pro-detail">
                        <span className="rev-pro-title">
                          Rachel Flare Ultra High Rise Instasculpt 35" | White
                          Raw
                        </span>
                        <span className="rev-pro-price">$33.99</span>
                      </div>
                    </NavLink>
                  </div>
                  <div className="event-product-box">
                    <NavLink to="/influencer-reviews" className="revi-products">
                      <div className="rev-pro-image">
                        <img src="https://cdn.shopify.com/s/files/1/2397/3099/products/20220122_DL1961_13245_EMILIE31_DARKOASIS_006.jpg?v=1643729652" />
                        <span className="rev-buy-now">
                          <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                        </span>
                      </div>
                      <div className="rev-pro-detail">
                        <span className="rev-pro-title">
                          Rachel Flare Ultra High Rise Instasculpt 35" | White
                          Raw
                        </span>
                        <span className="rev-pro-price">$33.99</span>
                      </div>
                    </NavLink>
                  </div>
                </Slider>
              </div>

              <div className="event-container">
                <h3>Other reviews</h3>
                <Slider {...otherreviews}>
                  <div className="event-col-box">
                    <NavLink to="/influencer-reviews" className="live-vid-link">
                      <div className="vid-item">
                        <img src="https://cdn.shopify.com/s/files/1/1698/6315/articles/154078066_3840355622708575_4418324325494105805_n_1024x.jpg?v=1614290282" />
                        <div className="vid-item-detail">
                          <div className="vid-time">
                            <span className="vid-views">
                              <i className="fa fa-play" aria-hidden="true"></i>{" "}
                              59.5k
                            </span>
                          </div>
                          <div className="vid-title">
                            <div className="author-name">
                              <span className="auth-image">
                                <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                              </span>
                              <span className="auth-title">
                                _alyssa_lorraine_
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                  <div className="event-col-box">
                    <a href="#" className="live-vid-link">
                      <div className="vid-item">
                        <img src="https://images.squarespace-cdn.com/content/v1/514ecafde4b045db9417fa2f/1634566581598-8Q7DGGYPQR8ZHBSYQ1MV/JR_Marlen_5x7_Postcard.jpg" />
                        <div className="vid-item-detail">
                          <div className="vid-time">
                            <span className="vid-views">
                              <i className="fa fa-play" aria-hidden="true"></i>{" "}
                              59.5k
                            </span>
                          </div>
                          <div className="vid-title">
                            <div className="author-name">
                              <span className="auth-image">
                                <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                              </span>
                              <span className="auth-title">
                                _alyssa_lorraine_
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="event-col-box">
                    <a href="#" className="live-vid-link">
                      <div className="vid-item">
                        <img src="https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/ff-collection-shot-models-original-1629924514.jpg" />
                        <div className="vid-item-detail">
                          <div className="vid-time">
                            <span className="vid-views">
                              <i className="fa fa-play" aria-hidden="true"></i>{" "}
                              59.5k
                            </span>
                          </div>
                          <div className="vid-title">
                            <div className="author-name">
                              <span className="auth-image">
                                <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                              </span>
                              <span className="auth-title">
                                _alyssa_lorraine_
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="event-col-box">
                    <a href="#" className="live-vid-link">
                      <div className="vid-item">
                        <img src="https://media1.popsugar-assets.com/files/thumbor/HDjdstm17IyKfb4D4dp21SZiHBw/fit-in/2048xorig/filters:format_auto-!!-:strip_icc-!!-/2018/08/29/701/n/44285655/1e399895360866ee_AdidasOriginal_FW18_Falcon_B28126_Look2_FullBody_0190_03/i/Kylie-Jenner-Adidas-Falcon-Sneakers-2018.jpg" />
                        <div className="vid-item-detail">
                          <div className="vid-time">
                            <span className="vid-views">
                              <i className="fa fa-play" aria-hidden="true"></i>{" "}
                              59.5k
                            </span>
                          </div>
                          <div className="vid-title">
                            <div className="author-name">
                              <span className="auth-image">
                                <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                              </span>
                              <span className="auth-title">
                                _alyssa_lorraine_
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="event-col-box">
                    <a href="#" className="live-vid-link">
                      <div className="vid-item">
                        <img src="https://cdn.shopify.com/s/files/1/2397/3099/products/13246_Hepburn_Keys_042_650x.jpg?v=1643730359" />
                        <div className="vid-item-detail">
                          <div className="vid-time">
                            <span className="vid-views">
                              <i className="fa fa-play" aria-hidden="true"></i>{" "}
                              59.5k
                            </span>
                          </div>
                          <div className="vid-title">
                            <div className="author-name">
                              <span className="auth-image">
                                <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                              </span>
                              <span className="auth-title">
                                _alyssa_lorraine_
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="event-col-box">
                    <a href="#" className="live-vid-link">
                      <div className="vid-item">
                        <img src="https://thevou.com/wp-content/uploads/2021/10/PlusSizeClothing.thevou.com_.warpweftworld.01.jpg" />
                        <div className="vid-item-detail">
                          <div className="vid-time">
                            <span className="vid-views">
                              <i className="fa fa-play" aria-hidden="true"></i>{" "}
                              59.5k
                            </span>
                          </div>
                          <div className="vid-title">
                            <div className="author-name">
                              <span className="auth-image">
                                <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                              </span>
                              <span className="auth-title">
                                _alyssa_lorraine_
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="event-col-box">
                    <a href="#" className="live-vid-link">
                      <div className="vid-item">
                        <img src="https://thevou.com/wp-content/uploads/2021/10/PlusSizeClothing.thevou.com_.warpweftworld.01.jpg" />
                        <div className="vid-item-detail">
                          <div className="vid-time">
                            <span className="vid-views">
                              <i className="fa fa-play" aria-hidden="true"></i>{" "}
                              59.5k
                            </span>
                          </div>
                          <div className="vid-title">
                            <div className="author-name">
                              <span className="auth-image">
                                <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                              </span>
                              <span className="auth-title">
                                _alyssa_lorraine_
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </Slider>
              </div>

              <div className="event-container">
                <h3>Brand Videos</h3>
                <Slider {...otherreviews}>
                  <div className="event-col-box">
                    <NavLink to="/influencer-reviews" className="live-vid-link">
                      <div className="vid-item">
                        <img src="https://cdn.shopify.com/s/files/1/1698/6315/articles/154078066_3840355622708575_4418324325494105805_n_1024x.jpg?v=1614290282" />
                        <div className="vid-item-detail">
                          <div className="vid-time">
                            <span className="vid-views">
                              <i className="fa fa-play" aria-hidden="true"></i>{" "}
                              59.5k
                            </span>
                          </div>
                          <div className="vid-title">
                            <span className="v-title">41 BEST Plus Size Jeans</span>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                  <div className="event-col-box">
                    <a href="#" className="live-vid-link">
                      <div className="vid-item">
                        <img src="https://images.squarespace-cdn.com/content/v1/514ecafde4b045db9417fa2f/1634566581598-8Q7DGGYPQR8ZHBSYQ1MV/JR_Marlen_5x7_Postcard.jpg" />
                        <div className="vid-item-detail">
                          <div className="vid-time">
                            <span className="vid-views">
                              <i className="fa fa-play" aria-hidden="true"></i>{" "}
                              59.5k
                            </span>
                          </div>
                          <div className="vid-title">
                            <span className="v-title">41 BEST Plus Size Jeans</span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="event-col-box">
                    <a href="#" className="live-vid-link">
                      <div className="vid-item">
                        <img src="https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/ff-collection-shot-models-original-1629924514.jpg" />
                        <div className="vid-item-detail">
                          <div className="vid-time">
                            <span className="vid-views">
                              <i className="fa fa-play" aria-hidden="true"></i>{" "}
                              59.5k
                            </span>
                          </div>
                          <div className="vid-title">
                            <span className="v-title">41 BEST Plus Size Jeans</span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="event-col-box">
                    <a href="#" className="live-vid-link">
                      <div className="vid-item">
                        <img src="https://media1.popsugar-assets.com/files/thumbor/HDjdstm17IyKfb4D4dp21SZiHBw/fit-in/2048xorig/filters:format_auto-!!-:strip_icc-!!-/2018/08/29/701/n/44285655/1e399895360866ee_AdidasOriginal_FW18_Falcon_B28126_Look2_FullBody_0190_03/i/Kylie-Jenner-Adidas-Falcon-Sneakers-2018.jpg" />
                        <div className="vid-item-detail">
                          <div className="vid-time">
                            <span className="vid-views">
                              <i className="fa fa-play" aria-hidden="true"></i>{" "}
                              59.5k
                            </span>
                          </div>
                          <div className="vid-title">
                            <span className="v-title">41 BEST Plus Size Jeans</span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="event-col-box">
                    <a href="#" className="live-vid-link">
                      <div className="vid-item">
                        <img src="https://cdn.shopify.com/s/files/1/2397/3099/products/13246_Hepburn_Keys_042_650x.jpg?v=1643730359" />
                        <div className="vid-item-detail">
                          <div className="vid-time">
                            <span className="vid-views">
                              <i className="fa fa-play" aria-hidden="true"></i>{" "}
                              59.5k
                            </span>
                          </div>
                          <div className="vid-title">
                            <span className="v-title">41 BEST Plus Size Jeans</span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="event-col-box">
                    <a href="#" className="live-vid-link">
                      <div className="vid-item">
                        <img src="https://thevou.com/wp-content/uploads/2021/10/PlusSizeClothing.thevou.com_.warpweftworld.01.jpg" />
                        <div className="vid-item-detail">
                          <div className="vid-time">
                            <span className="vid-views">
                              <i className="fa fa-play" aria-hidden="true"></i>{" "}
                              59.5k
                            </span>
                          </div>
                          <div className="vid-title">
                            <span className="v-title">41 BEST Plus Size Jeans</span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="event-col-box">
                    <a href="#" className="live-vid-link">
                      <div className="vid-item">
                        <img src="https://thevou.com/wp-content/uploads/2021/10/PlusSizeClothing.thevou.com_.warpweftworld.01.jpg" />
                        <div className="vid-item-detail">
                          <div className="vid-time">
                            <span className="vid-views">
                              <i className="fa fa-play" aria-hidden="true"></i>{" "}
                              59.5k
                            </span>
                          </div>
                          <div className="vid-title">
                            <span className="v-title">41 BEST Plus Size Jeans</span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        backdropClassName="custom-backdrop"
        dialogClassName="influ-products-modal-box"
        show={showProducts}
        scrollable
        onHide={() => {
          setShowProducts(false);
        }}
        centered
      >
        <span
          className="filter-close"
          onClick={() => {
            setShowProducts(false);
          }}
        >
          <img src={closeicon} className="filter-gray" />
        </span>
        <Modal.Body>
          <div className="pro-list-main">
            <h2>Products in this Live Show</h2>
            <div className="pro-list-inner">
              <div className="list-col">
                <div className="prod-box">
                  <div className="prod-image">
                    <img src="https://cdn.shopify.com/s/files/1/2397/3099/products/20220122_DL1961_13248_BRIDGET30_TORNADO_004.jpg?v=1643729927" />
                  </div>
                  <div className="prod-name">
                    <div className="prod-title">
                      <span className="prod-name-inr">
                        Ella Straight: Ultra High Rise Vintage 32" | Droplet
                      </span>
                    </div>
                    <div className="prod-price">
                      <span className="prod-inr-price">
                        <span>$188.10</span>
                        <span className="original-price">$209.00</span>
                      </span>
                    </div>
                  </div>
                  <div className="prod-cart-btn">
                    <a href="#">Buy Now</a>
                  </div>
                </div>
              </div>
              <div className="list-col">
                <div className="prod-box">
                  <div className="prod-image">
                    <img src="https://cdn.shopify.com/s/files/1/2397/3099/products/20220122_DL1961_13248_BRIDGET30_TORNADO_004.jpg?v=1643729927" />
                  </div>
                  <div className="prod-name">
                    <div className="prod-title">
                      <span className="prod-name-inr">
                        Ella Straight: Ultra High Rise Vintage 32" | Droplet
                      </span>
                    </div>
                    <div className="prod-price">
                      <span className="prod-inr-price">
                        <span>$188.10</span>
                        <span className="original-price">$209.00</span>
                      </span>
                    </div>
                  </div>
                  <div className="prod-cart-btn">
                    <a href="#">Buy Now</a>
                  </div>
                </div>
              </div>
              <div className="list-col">
                <div className="prod-box">
                  <div className="prod-image">
                    <img src="https://cdn.shopify.com/s/files/1/2397/3099/products/20220122_DL1961_13248_BRIDGET30_TORNADO_004.jpg?v=1643729927" />
                  </div>
                  <div className="prod-name">
                    <div className="prod-title">
                      <span className="prod-name-inr">
                        Ella Straight: Ultra High Rise Vintage 32" | Droplet
                      </span>
                    </div>
                    <div className="prod-price">
                      <span className="prod-inr-price">
                        <span>$188.10</span>
                        <span className="original-price">$209.00</span>
                      </span>
                    </div>
                  </div>
                  <div className="prod-cart-btn">
                    <a href="#">Buy Now</a>
                  </div>
                </div>
              </div>
              <div className="list-col">
                <div className="prod-box">
                  <div className="prod-image">
                    <img src="https://cdn.shopify.com/s/files/1/2397/3099/products/20220122_DL1961_13248_BRIDGET30_TORNADO_004.jpg?v=1643729927" />
                  </div>
                  <div className="prod-name">
                    <div className="prod-title">
                      <span className="prod-name-inr">
                        Ella Straight: Ultra High Rise Vintage 32" | Droplet
                      </span>
                    </div>
                    <div className="prod-price">
                      <span className="prod-inr-price">
                        <span>$188.10</span>
                        <span className="original-price">$209.00</span>
                      </span>
                    </div>
                  </div>
                  <div className="prod-cart-btn">
                    <a href="#">Buy Now</a>
                  </div>
                </div>
              </div>
              <div className="list-col">
                <div className="prod-box">
                  <div className="prod-image">
                    <img src="https://cdn.shopify.com/s/files/1/2397/3099/products/20220122_DL1961_13248_BRIDGET30_TORNADO_004.jpg?v=1643729927" />
                  </div>
                  <div className="prod-name">
                    <div className="prod-title">
                      <span className="prod-name-inr">
                        Ella Straight: Ultra High Rise Vintage 32" | Droplet
                      </span>
                    </div>
                    <div className="prod-price">
                      <span className="prod-inr-price">
                        <span>$188.10</span>
                        <span className="original-price">$209.00</span>
                      </span>
                    </div>
                  </div>
                  <div className="prod-cart-btn">
                    <a href="#">Buy Now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
