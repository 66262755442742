import { useEffect, useState, useRef } from "react";
import { Button } from "react-bootstrap";
import "../../css/live-events.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CategoryFilterModal from "../../components/categoryFilterModal/categoryFilterModal";
import { useDispatch, useSelector } from "react-redux";
import UpComingEvent from "../../components/upComingEvent/upComingEvent";
import LiveEvent from "../../components/liveEvent/liveEvent";
import PublishEvent from "../../components/publishEvent/publishEvent";
import PopularChannel from "../../components/popularChannel/popularChannel";
import { getAllPublishedEvent } from "../../redux/actions/liveShopping.action";

export default function LiveShopping() {
	const [showFilter, setShowFilter] = useState(false);
	const [category, setCategory] = useState([]);
	const dispatch = useDispatch();
	const [data, setData] = useState([]);
	const { brand } = useSelector((state) => {
		return state;
	});

	useEffect(() => {
		if (brand?.payload?.success) {
			dispatch(getAllPublishedEvent(1, 25, category, brand?.payload?.message)).then((res) => {
				setData(res?.payload?.message?.data);
			});
		}
	}, [category]);

	let userInfo = localStorage.getItem("userInfo");
	if (userInfo !== "undefined") {
		userInfo = JSON.parse(localStorage.getItem("userInfo"));
	} else {
		localStorage.removeItem("userInfo");
		window.location.reload();
	}

	return (
		<>
			<div className="live-event-main">
				<div className="midd-width-ifti">
					<div className="live-shows">
						<div className="event-filter">
							<Button
								onClick={() => {
									setShowFilter(true);
								}}
								variant="primary"
							>
								Filters
							</Button>{" "}
						</div>
						{!brand?.loading && <LiveEvent category={category} />}
						{!brand?.loading && <UpComingEvent category={category} />}
						{!brand?.loading && <PublishEvent category={category} />}
						<PopularChannel />
					</div>
				</div>
			</div>
			<CategoryFilterModal setCategory={setCategory} showFilter={showFilter} setShowFilter={setShowFilter} />
		</>
	);
}
