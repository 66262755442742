import { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import "antd/dist/antd.css";
import "../../../css/account-setup.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGauge, faUser, faCreditCard, faTruckFast, faBagShopping, faChartLine, faDollarSign, faHandHoldingDollar, faHeart, faUsers, faPeopleRoof } from "@fortawesome/free-solid-svg-icons";
import { Layout, Menu } from "antd";
import React from "react";
const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;
export default ({ children, title }) => {
	const location = useLocation();
	const { account } = useSelector((state) => state);
	const [activeLink, setActiveLink] = useState("");
	useEffect(() => {
		setActiveLink(window.location.pathname);
	}, []);

	let userInfo = localStorage.getItem("userInfo");

	if (userInfo !== "undefined") {
		userInfo = JSON.parse(localStorage.getItem("userInfo"));
	} else {
		localStorage.removeItem("userInfo");
		window.location.reload();
	}

	// This is componentWillUnmount
	const [chatWidth, setChatWidth] = useState(undefined);
	const [sidebarTop, setSidebarTop] = useState(undefined);

	useEffect(() => {
		const chatEl = document.querySelector(".socialsidebar").getBoundingClientRect();
		setChatWidth(chatEl.width);
		setSidebarTop(chatEl.top);
	}, []);

	useEffect(() => {
		if (!sidebarTop) return;

		window.addEventListener("scroll", isSticky);
		return () => {
			window.removeEventListener("scroll", isSticky);
		};
	}, [sidebarTop]);

	const isSticky = () => {
		const chatEl = document.querySelector(".socialsidebar");
		const scrollTop = window.scrollY;
		if (scrollTop >= sidebarTop - 10) {
			chatEl.classList.add("is-sticky");
		} else {
			chatEl.classList.remove("is-sticky");
		}
	};

	const rootSubmenuKeys = ["accounting", "settings", "ugc", "social-store-management"];
	const [openKeys, setOpenKeys] = useState([]);

	const onOpenChange = (keys, e) => {
		const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
		if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
			setOpenKeys(keys);
		} else {
			setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
		}
	};
	return (
		<>
			<div className="account-container-main">
				<Layout className="ant-layout-has-sider">
					<Sider
						className="sidebar-main socialsidebar"
						width={250}
						breakpoint="xl"
						collapsedWidth="0"
						onBreakpoint={(broken) => {
							console.log(broken);
						}}
						onCollapse={(collapsed, type) => {
							console.log(collapsed, type);
						}}
					>
						<h5 className="wordspace-hd">Welcome</h5>
						<div className="workspace-menu">
							<div className="store-menu">
								<div className="workspace-icon">
									<i className="fa fa-user" aria-hidden="true"></i>
								</div>
								<div className="workspace-menu-item">
									<div className="store-name-txt">{account?.payload?.Data?.name}</div>
								</div>
							</div>
						</div>
						<Menu
							// theme="dark"
							openKeys={window.innerWidth >= 1200 ? ["social-store-management"] : openKeys}
							onOpenChange={onOpenChange}
							mode="inline"
							popupClassName="test"
							popupOffset={0}
							className="menu-style-new"
							defaultSelectedKeys={location.pathname.split("/")[1]}
							theme="light"

							// defaultOpenKeys={["1"]}
						>
							<Menu.Item>
								<Link className={"/dashboard" === activeLink && `active`} to="/dashboard">
									<FontAwesomeIcon icon={faGauge} />
									<span className="nav-text">Dashboard</span>
								</Link>
							</Menu.Item>
							<Menu.Item>
								<Link className={("/myaccount" === activeLink && `active`) || ("/accountDetail" === activeLink && `active`)} to="/myaccount">
									<FontAwesomeIcon icon={faUser} />
									<span className="nav-text">Profile</span>
								</Link>
							</Menu.Item>
							{/* <Menu.Item>
								<Link className={"/payment" === activeLink && `active`} to="/payment">
									<FontAwesomeIcon icon={faCreditCard} />
									<span className="nav-text">Payment Method</span>
								</Link>
							</Menu.Item> */}
							<Menu.Item>
								<Link className={"/shippingaddress" === activeLink && `active`} to="/shippingaddress">
									<FontAwesomeIcon icon={faTruckFast} />
									<span className="nav-text">Shipping Information</span>
								</Link>
							</Menu.Item>
							<Menu.Item>
								<Link className={"/purchase" === activeLink && `active`} to="/purchase">
									<FontAwesomeIcon icon={faBagShopping} />
									<span className="nav-text">Purchases</span>
								</Link>
							</Menu.Item>
							<Menu.Item>
								<Link className={"/referralstats" === activeLink && `active`} to="/referralstats">
									<FontAwesomeIcon icon={faChartLine} />
									<span className="nav-text">Referral Stats</span>
								</Link>
							</Menu.Item>
							<Menu.Item>
								<Link className={"/earned-commission" === activeLink && `active`} to="/earned-commission">
									<FontAwesomeIcon icon={faDollarSign} />
									<span className="nav-text">Earnings</span>
								</Link>
							</Menu.Item>
							<Menu.Item>
								<Link className={"/mywallet" === activeLink && `active`} to="/mywallet">
									<FontAwesomeIcon icon={faHandHoldingDollar} />
									<span className="nav-text">Payout</span>
								</Link>
							</Menu.Item>
							<Menu.Item>
								<Link className={"/favourite" === activeLink && `active`} to="/favourite">
									<FontAwesomeIcon icon={faHeart} />
									<span className="nav-text">Favourites</span>
								</Link>
							</Menu.Item>
							{userInfo?.account_type !== "brand" && account?.payload?.Data?.became_influencer !== "approved" && (
								<Menu.Item>
									<Link className={"/become-influencer" === activeLink && `active`} to="/become-influencer">
										<FontAwesomeIcon icon={faUsers} />
										<span className="nav-text">{account?.payload?.Data?.became_influencer === "approved" ? "Influencer Section" : "Become an Influencer"}</span>
									</Link>
								</Menu.Item>
							)}
							{userInfo?.account_type !== "brand" && account?.payload?.Data?.became_influencer === "approved" && (
								<Menu.SubMenu
									className="test"
									key="social-store-management"
									title={
										<span>
											<FontAwesomeIcon icon={faPeopleRoof} />
											<span className="nav-text">Social Store Management</span>
										</span>
									}
								>
									{userInfo?.account_type !== "brand" && account?.payload?.Data?.became_influencer === "approved" && (
										<Menu.Item>
											<Link className={"/become-influencer" === activeLink && `active`} to="/become-influencer">
												{/* <FontAwesomeIcon icon={faUsers} /> */}
												<span className="nav-text">{account?.payload?.Data?.became_influencer === "approved" ? "Influencer Section" : "Become an Influencer"}</span>
											</Link>
										</Menu.Item>
									)}

									<>
										<Menu.Item
											onClick={() => {
												onOpenChange("social-store-management");
											}}
										>
											<Link style={{ pointerEvents: account?.payload?.Data?.instagram_token ? "all" : "none" }} className={`/influencer-socialstore/${account?.payload?.Data?.instagram_username}/${account?.payload?.Data?.instagram_token}` === activeLink && "active"} to={`/influencer-socialstore/${account?.payload?.Data?.instagram_username}/${account?.payload?.Data?.instagram_token}`}>
												{/* <FontAwesomeIcon icon={faHeart} /> */}
												<span className="nav-text">Manage Social Store</span>
											</Link>
										</Menu.Item>

										<Menu.Item>
											<Link style={{ pointerEvents: account?.payload?.Data?.instagram_token ? "all" : "none" }} className={"/reorder-socialstore" === activeLink && `active`} to="/reorder-socialstore">
												{/* <FontAwesomeIcon icon={faHeart} /> */}
												<span className="nav-text">Reorder Store</span>
											</Link>
										</Menu.Item>

										<Menu.Item>
											<Link style={{ pointerEvents: account?.payload?.Data?.instagram_token ? "all" : "none" }} className={"/mediagallery" === activeLink && `active`} to="/mediagallery">
												{/* <FontAwesomeIcon icon={faHeart} /> */}
												<span className="nav-text">Manage Gallery</span>
											</Link>
										</Menu.Item>

										<Menu.Item>
											<Link style={{ pointerEvents: account?.payload?.Data?.instagram_token ? "all" : "none" }} className={"/manageCategory" === activeLink && `active`} to="/manageCategory">
												{/* <FontAwesomeIcon icon={faHeart} /> */}
												<span className="nav-text">Manage Category</span>
											</Link>
										</Menu.Item>
									</>
								</Menu.SubMenu>
							)}
						</Menu>
					</Sider>

					<Layout className="layout-content">
						<Header
							className="site-layout-sub-header-background"
							style={{
								padding: 0,
							}}
						>
							<div className="header-title">{title}</div>
						</Header>
						<Content style={{ margin: "0" }}>
							<div className="site-layout-background">
								<div className="account-inr-container">{children}</div>
							</div>
						</Content>
					</Layout>
				</Layout>
			</div>
		</>
	);
};
